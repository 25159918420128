import * as Mui from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import image2 from "assets/fshaltm/mountain-landscape-2031539_1280.jpg";
import MemberCard from "components/members/MemberCard";
import MemberContainer from "components/members/MemberContainer";
import { MemberFormPopup } from "components/members/MemberFormPopup";
import { PageHeader } from "components/PageHeader";
import { ParallaxContainer } from "components/ParallaxContainer";
import { Plus } from "components/Plus";
import { useAuthentication } from "hooks/useAuthentication";
import { IMembers } from "interfaces/IMembers";
import { Positives } from "pages/components/Positives";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MembersService } from "service/MembersService";
import { queryKeys } from "service/tanstack";
import { sqids } from "utils/Sqids";

interface Props {}

const NgjitjaSportive = (props: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const isAuthenticated = useAuthentication();

  const [openPopup, setopenPopup] = useState<boolean>(false);
  const [ngjitjeSportiveEdit, setNgjitjeSportiveEdit] = useState<IMembers>();

  const members = useQuery({
    queryKey: [queryKeys.members.getNgjitjeSportive],
    queryFn: MembersService.getNgjitjaSportive,
  });

  const remove = useMutation({
    mutationFn: MembersService.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.members.getNgjitjeSportive],
      });
    },
  });

  const updateNgjitjeSportive = (guida: IMembers) => {
    setNgjitjeSportiveEdit(guida);
    setopenPopup(true);
  };

  const onDelete = (id: number) => {
    remove.mutate(id);
  };

  const onClosePopUp = () => {
    setNgjitjeSportiveEdit(undefined);
    setopenPopup(false);
  };

  return (
    <>
      <PageHeader
        title="Ngjitja sportive"
        subtitle="Anetaret ne shqiperi"
        parallax={<ParallaxContainer image={image2} animated />}
        breadcrumbs={[
          {
            title: "home",
            to: "/",
          },
          {
            title: "ngjitja sportive",
          },
        ]}
      />

      <Mui.Stack>
        <MemberContainer>
          {members.data?.map((member, index) => {
            return (
              <MemberCard
                key={`member-${index}-${member.name}`}
                member={member}
                onClickItem={() =>
                  navigate(`/member/${sqids.encode([member.id])}`, {
                    state: {
                      origin: "ngjitja-sportive",
                    },
                  })
                }
                onEditItem={() => updateNgjitjeSportive(member)}
                onDeleteItem={() => onDelete(member.id)}
              />
            );
          })}

          {isAuthenticated && <Plus buttonOnClick={() => setopenPopup(true)} />}
        </MemberContainer>

        <Mui.Box
          sx={{
            px: {
              xs: 5,
              md: 5,
              lg: 15,
            },
            py: {
              xs: 5,
              md: 10,
              lg: 15,
            },
          }}
        >
          <Positives />
        </Mui.Box>
      </Mui.Stack>

      <MemberFormPopup
        open={openPopup}
        onClose={onClosePopUp}
        member={ngjitjeSportiveEdit}
        memberType="NGJITJE_SPORTIVE"
      />
    </>
  );
};

export default NgjitjaSportive;
