import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import * as Mui from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import { AlpinSettings } from "utils/AlpinSettings";
import { alpinTheme } from "theme/AlpinTheme";
import { useLocation } from "react-router-dom";

type Props = {} & PropsWithChildren;

export const AppBar = (props: Props) => {
  const location = useLocation();
  const [bgColor, setBgColor] = useState("transparent");

  const isHome = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    }

    return false;
  }, [location.pathname]);

  const [springs, api] = useSpring(() => ({
    from: {
      background: "transparent",
    },
  }));

  useEffect(() => {
    if (bgColor === "transparent") {
      api.start({
        from: {
          background: "transparent",
        },
        to: {
          background: alpinTheme.palette.primary.main,
        },
      });
    } else if (bgColor === alpinTheme.palette.primary.main) {
      api.start({
        from: {
          background: alpinTheme.palette.primary.main,
        },
        to: {
          background: "transparent",
        },
      });
    }
  }, [bgColor]);

  const handleScroll = () => {
    const yOffset = 200;
    if (window.pageYOffset > yOffset) {
      setBgColor("transparent");
    } else if (window.pageYOffset <= yOffset) {
      setBgColor(alpinTheme.palette.primary.main);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const AnimatedAppBar = animated(Mui.AppBar);
  if (isHome) {
    return (
      <AnimatedAppBar
        style={{
          height: AlpinSettings.topBar.height,
          borderBottom: "none",
          boxShadow: "none",
          ...springs,
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        {props.children}
      </AnimatedAppBar>
    );
  }

  return (
    <Mui.AppBar
      style={{
        height: AlpinSettings.topBar.height,
        borderBottom: "none",
        boxShadow: "none",
        background: alpinTheme.palette.primary.main,
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      {props.children}
    </Mui.AppBar>
  );
};
