import * as Mui from "@mui/material";
import { AlpinFooter } from "common/AlpinFooter";
import { TopBar } from "common/TopBar";
import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AlpinSettings } from "utils/AlpinSettings";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import Loader from "components/Loader";

interface Props {};

const Root = (props: Props) => {
  const location = useLocation();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const isLoading = isMutating > 0 || isFetching > 0;

  const transparentAppBar = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    }

    return false;
  }, [location])
  
  return (
    <>
      <TopBar />
      
      <Mui.Box
        sx={{
          marginTop: AlpinSettings.topBar.height,
        }}
      >
        <Outlet />
      </Mui.Box>

      <Loader open={isLoading} />

      <AlpinFooter />
    </>
  )
}

export default Root