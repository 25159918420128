import * as Mui from "@mui/material";
import { PropsWithChildren } from "react";
import { alpinTheme } from "theme/AlpinTheme";
import { AlpinSettings } from "utils/AlpinSettings";

type Props = {
  title: string;
  subtitle?: string;
  parallax?: React.ReactNode | React.ReactElement;
  breadcrumbs?: {
    title: string;
    to?: string;
  }[];
} & PropsWithChildren;

export const PageHeader = (props: Props) => {
  return (
    <Mui.Box
      display={"flex"}
      flexDirection={"column"}
      height={{
        md: "500px",
        xs: "250px",
      }}
      position={"relative"}
    >
      {props.parallax}

      <Mui.Grid
        container
        mt={"auto"}
        height={"fit-content"}
        spacing={0}
        direction={{
          md: "row-reverse",
          xs: "row",
        }}
        px={{
          md: 10,
          xs: 1,
        }}
      >
        <Mui.Grid
          item
          display={"flex"}
          justifyContent={{
            md: "end",
            xs: "start",
          }}
          xs={12}
          md={6}
          height={"fit-content"}
          alignSelf={"center"}
        >
          <Mui.Breadcrumbs color="white">
            {props.breadcrumbs?.map((element, index) => {
              if (
                props.breadcrumbs &&
                index === props.breadcrumbs?.length - 1
              ) {
                return (
                  <Mui.Typography
                    key={`breadcrumb-${element.title}-${index}`}
                    color="white"
                    variant="h6"
                  >
                    {element.title}
                  </Mui.Typography>
                );
              }

              return (
                <Mui.Link
                  key={`breadcrumb-${element.title}-${index}`}
                  underline="none"
                  href={element.to}
                >
                  <Mui.Typography color="white" variant="h6">
                    {element.title}
                  </Mui.Typography>
                </Mui.Link>
              );
            })}
          </Mui.Breadcrumbs>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={6} height={"fit-content"} mt="auto">
          <Mui.Typography variant="h1" color={"white"} flexShrink={0}>
            {props.title}
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>

      {props.subtitle && (
        <Mui.Stack
          direction={"row"}
          alignItems={"center"}
          sx={{
            pl: {
              md: 10,
              xs: 1,
            },
            pb: 2,
          }}
        >
          <Mui.Box
            sx={{
              height: "6px",
              backgroundColor: "primary.main",
              width: {
                md: "80px",
                xs: "0px",
              },
              ml: {
                md: -12,
                xs: -2,
              },
              borderRadius: "25px",
            }}
          />

          <Mui.Typography
            variant="h4"
            sx={{
              mx: "20px",
            }}
            color="white"
          >
            {props.subtitle}
          </Mui.Typography>

          <Mui.Box
            sx={{
              height: "6px",
              backgroundColor: "primary.main",
              borderRadius: "25px",
              flexGrow: 1,
              mt: "unset",
            }}
          />
        </Mui.Stack>
      )}
    </Mui.Box>
  );
};
