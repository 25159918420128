import * as Mui from "@mui/material";
import MoreActionsMenu from "components/MoreActionsMenu";
import { useAuthentication } from "hooks/useAuthentication";
import { IAboutus } from "interfaces/IAboutus";

interface IAboutUsProps {
  item: IAboutus;
  onEdit: () => void;
  onDelete: () => void;
}

const AboutUsItem = (props: IAboutUsProps) => {
  const isAuthenticated = useAuthentication();
  return (
    <Mui.Paper sx={{ p: 2 }}>
      <Mui.Stack spacing={2}>
        <Mui.Typography variant="h5">{props.item.title}</Mui.Typography>

        <Mui.Typography variant="body1" whiteSpace={"pre-line"}>
          {props.item.description}
        </Mui.Typography>

        {isAuthenticated && (
          <Mui.Stack direction={"row"} justifyContent={"end"}>
            <MoreActionsMenu onEdit={props.onEdit} onDelete={props.onDelete} />
          </Mui.Stack>
        )}
      </Mui.Stack>
    </Mui.Paper>
  );
};

export default AboutUsItem;
