import Anetaret from "common/Anetaret";
import { AboutUs } from "pages/aboutus/AboutUs";
import Associations from "pages/association/Associations";
import Authentication from "pages/authentication/Authentication";
import { Events } from "pages/events/Events";
import Guidat from "pages/guidat/Guidat";
import Home from "pages/home/Home";
import { PersonelPage } from "pages/members/PersonelPage";
import NgjitjaSportive from "pages/ngjitjaSportive/NgjitjaSportive";
import ShpetimiMalor from "pages/shpetimMalor/ShpetimiMalor";
import { createBrowserRouter } from "react-router-dom";
import Root from "./Root";

export const router = createBrowserRouter([
  {
    path: "/",
    Component: Root,
    children: [
      {
        path: "/",
        Component: Home,
        index: true,
      },
      {
        path: "/login",
        Component: Authentication,
      },
      {
        path: "shoqata",
        Component: Associations,
      },
      {
        path: "guidat",
        Component: Guidat,
      },
      {
        path: "member/:id",
        Component: PersonelPage,
      },
      {
        path: "shpetimi-malor",
        Component: ShpetimiMalor,
      },
      {
        path: "ngjitja-sportive",
        Component: NgjitjaSportive,
      },
      {
        path: "anetaret/:id",
        Component: Anetaret,
      },
      {
        path: "rreth-nesh",
        Component: AboutUs,
      },
      {
        path: "eventet",
        Component: Events,
      },
    ],
  },
]);
