import axios from "axios";

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 10000
});

// So that authorization is refreshed with page refresh
AxiosInstance.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
