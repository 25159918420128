import * as Mui from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import image2 from "assets/fshaltm/mountain-landscape-2031539_1280.jpg";
import ImageSkeleton from "components/ImageSkeleton";
import { PageHeader } from "components/PageHeader";
import { ParallaxContainer } from "components/ParallaxContainer";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { MembersService } from "service/MembersService";
import { queryKeys } from "service/tanstack";
import { AlpinSettings } from "utils/AlpinSettings";
import { sqids } from "utils/Sqids";

type Props = {};

export const PersonelPage = (props: Props) => {
  const { state } = useLocation();

  const { origin, originName } = state;

  const { id } = useParams();

  const loadMember = () => {
    if (id) {
      const memberIds = sqids.decode(id);
      return MembersService.getById(memberIds[0]);
    }
  };

  const member = useQuery({
    queryKey: [queryKeys.members.getbyid],
    queryFn: loadMember,
  });

  if (!id || !member.data) {
    return (
      <Mui.Stack>
        <Mui.Box>
          <Mui.Typography variant="h1">User not found</Mui.Typography>
        </Mui.Box>
      </Mui.Stack>
    );
  }

  return (
    <>
      <Mui.Stack position={"relative"}>
        <PageHeader
          title={member.data.name}
          breadcrumbs={[
            {
              title: "Home",
              to: "/",
            },
            {
              title: originName || origin,
              to: `${origin}`,
            },
            {
              title: member.data.name,
            },
          ]}
          parallax={<ParallaxContainer image={image2} animated />}
        />

        <Mui.Box
          sx={{
            backgroundColor: "#eef3f7",
            py: {
              xs: 5,
              md: 10,
              lg: 15,
            },
            px: {
              xs: 1,
              md: 8,
              lg: 15,
            },
          }}
        >
          <Mui.Paper
            sx={{
              px: {
                xs: 1,
                md: 2,
                lg: 5,
              },
              py: 1,
            }}
          >
            <Mui.Grid container spacing={2}>
              <Mui.Grid
                item
                xs={12}
                md={4}
                lg={6}
                display={"flex"}
                alignItems={"center"}
              >
                {/* <img src={""} alt='test' width='100%' height='100%' /> */}
                <ImageSkeleton src={member.data.image} />
              </Mui.Grid>

              <Mui.Grid item xs={12} md={8} lg={6}>
                <Mui.Stack width={"calc(100% - 300px)"}>
                  <Mui.Typography component={"span"} variant="h4">
                    {member.data.name}, {member.data.categorization}
                  </Mui.Typography>

                  <br />

                  <Mui.Typography component={"span"} variant="body1">
                    <b>Emer mbiemer:</b> {member.data.name}
                  </Mui.Typography>

                  <Mui.Typography component={"span"} variant="body1">
                    <b>Datelindje:</b>{" "}
                    {dayjs(member.data.dateofbirth).format(
                      AlpinSettings.dateformat
                    )}
                  </Mui.Typography>

                  <Mui.Typography component={"span"} variant="body1">
                    <b>Vendbanim:</b> {member.data.place}
                  </Mui.Typography>

                  <Mui.Typography component={"span"} variant="body1">
                    <b>Kategorizimi:</b> {member.data.categorization}
                  </Mui.Typography>

                  <Mui.Typography component={"span"} variant="body1">
                    <b>Lloji i guides:</b> {member.data.position}
                  </Mui.Typography>

                  <Mui.Typography component={"span"} variant="body1">
                    <b>Pershkrimi: </b>
                  </Mui.Typography>

                  <Mui.Typography
                    component={"span"}
                    variant="body1"
                    whiteSpace={"pre-line"}
                  >
                    {member.data.description}
                  </Mui.Typography>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Paper>
        </Mui.Box>
      </Mui.Stack>
    </>
  );
};
