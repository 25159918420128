import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { QuoteChip } from "components";

type Props = {};

const HomeService = (props: Props) => {
  return (
    <Mui.Box
      sx={{
        px: {
          xs: 5,
          md: 5,
          lg: 15,
        },
        py: {
          xs: 5,
          md: 10,
          lg: 15,
        },
      }}
    >
      <Mui.Stack spacing={5}>
        <Mui.Typography variant="h4" color="text.primary" textAlign="center">
          TOP SERVICE
        </Mui.Typography>
        <Mui.Typography textAlign="center" variant="h4" fontWeight="500">
          <QuoteChip /> We provide the best experience
          <QuoteChip />
        </Mui.Typography>

        <Mui.Grid container spacing={2}>
          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign={"center"}
            borderBottom={{
              xs: "1px solid lightgray",
            }}
            borderRight={{
              sm: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.DoNotStep
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                Experienced team
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                We always lived inside our amazing nature
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            borderBottom={{
              xs: "1px solid lightgray",
            }}
            borderRight={{
              lg: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.FilterVintage
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                Easy joining
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                Contact us and in 2 minutes you`re booked
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            borderBottom={{
              xs: "1px solid lightgray",
            }}
            borderRight={{
              sm: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.TerrainOutlined
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                Expert hikers
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                Our mountans teached us everything we know
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            borderBottom={{
              xs: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.Forest
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                Secret locations
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                Discover hidden natural places with our tours
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            borderBottom={{
              xs: "1px solid lightgray",
              lg: "none",
            }}
            borderRight={{
              sm: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.EmojiFlags
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                Europe locations
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                We provide trips on varous europe countries
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            borderBottom={{
              xs: "1px solid lightgray",
              lg: "none",
            }}
            borderRight={{
              lg: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.Map sx={{ color: "primary.main", fontSize: "50px" }} />
              <Mui.Typography variant="h4" color="text.primary">
                Visit us
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                We are in the north of italy near the mountains
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            borderRight={{
              sm: "1px solid lightgray",
            }}
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.Language
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                United States
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                Once a year join us to the other part of the world
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid
            item
            lg={3}
            sm={6}
            xs={12}
            textAlign="center"
            p={{
              xs: 1,
              sm: 2,
              lg: 4,
            }}
          >
            <Mui.Stack spacing={2} alignItems="center" textAlign="center">
              <MuiIcons.FilterDrama
                sx={{ color: "primary.main", fontSize: "50px" }}
              />
              <Mui.Typography variant="h4" color="text.primary">
                Great waterfall
              </Mui.Typography>

              <Mui.Typography color="primary.200">
                Our excursions will bring you to great waterfalls
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Stack>
    </Mui.Box>
  );
};

export default HomeService;
