import * as Mui from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { PositivesServices } from "service/PositivesService";
import { queryKeys } from "service/tanstack";

interface Props {}

export const Positives = (props: Props) => {
  const positives = useQuery({
    queryKey: [queryKeys.positives.getall],
    queryFn: PositivesServices.getAll,
  });

  return (
    <Mui.Grid container gap={10} justifyContent="center">
      {positives.data?.map((positive, i) => {
        return (
          <Mui.Grid item xs={12} sm={12} md={3} display="flex" key={i} gap={2}>
            <i
              className={positive.icon}
              style={{ fontSize: "50px", color: "#379cf4" }}
            />
            <Mui.Stack spacing={2}>
              <Mui.Typography variant="h5">{positive.title}</Mui.Typography>

              <Mui.Typography variant="body1" color="primary.200">
                {positive.description}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        );
      })}
    </Mui.Grid>
  );
};
