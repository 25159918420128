import * as Mui from "@mui/material";
import image2 from "assets/fshaltm/mountain-landscape-2031539_1280.jpg";
import { PageHeader } from "components/PageHeader";
import { ParallaxContainer } from "components/ParallaxContainer";
import { Plus } from "components/Plus";
import MemberCard from "components/members/MemberCard";
import MemberContainer from "components/members/MemberContainer";
import { MemberFormPopup } from "components/members/MemberFormPopup";
import { IAssociation } from "interfaces/IAssociation";
import { IMembers } from "interfaces/IMembers";
import { Positives } from "pages/components/Positives";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AssociationService } from "service/AssociationService";
import { sqids } from "utils/Sqids";
import { MembersService } from "../service/MembersService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "service/tanstack";
import { useAuthentication } from "hooks/useAuthentication";

interface PropsAnetaret {}

const Anetaret = (props: PropsAnetaret) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const isAuthenticated = useAuthentication();

  const [openPopup, setopenPopup] = useState<boolean>(false);
  const [membersEdit, setMembersEdit] = useState<IMembers>();

  const loadData = () => {
    if (id) {
      const associationId = sqids.decode(id);
      return AssociationService.getById(associationId[0]);
    }
  };

  const association = useQuery({
    queryKey: [queryKeys.assocaitions.getbyid],
    queryFn: loadData,
  });

  const updateMembers = (member: IMembers) => {
    setMembersEdit(member);
    setopenPopup(true);
  };

  const remove = useMutation({
    mutationFn: MembersService.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.members.getall],
      });
    },
  });

  const deleteMember = (id: number) => {
    remove.mutate(id);
  };

  const onClosePopUp = () => {
    setMembersEdit(undefined);
    setopenPopup(false);
  };

  return (
    <>
      <PageHeader
        title="Anetaret"
        subtitle={association.data?.name}
        parallax={<ParallaxContainer image={image2} animated />}
        breadcrumbs={[
          {
            title: "Home",
            to: "/",
          },
          {
            title: "shoqatat",
            to: "shoqata",
          },
          {
            title: association.data?.name || "",
          },
        ]}
      />

      <Mui.Stack bgcolor="background.paper">
        <Mui.Box
          sx={{
            px: {
              xs: 5,
              md: 5,
              lg: 15,
            },
            py: {
              xs: 5,
              md: 10,
              lg: 15,
            },
          }}
        >
          <MemberContainer>
            {association.data?.members?.map((member, index) => {
              return (
                <MemberCard
                  key={`member-${index}-${member.name}`}
                  member={member}
                  onClickItem={() =>
                    navigate(`/member/${sqids.encode([member.id])}`, {
                      state: {
                        origin: `anetaret/${id}`,
                        originName: "anetaret",
                      },
                    })
                  }
                  onEditItem={() => updateMembers(member)}
                  onDeleteItem={() => deleteMember(member.id)}
                />
              );
            })}

            {isAuthenticated && (
              <Plus buttonOnClick={() => setopenPopup(true)} />
            )}
          </MemberContainer>
        </Mui.Box>

        <Positives />
      </Mui.Stack>

      <MemberFormPopup
        onClose={() => onClosePopUp()}
        open={openPopup}
        member={membersEdit}
        association={association.data}
      />
    </>
  );
};
export default Anetaret;
