import { CSSProperties, PropsWithChildren, ReactNode, useId, useMemo } from "react";
import { Parallax } from "react-scroll-parallax";
import { PropsParallax } from "./Common";
import { Container } from "./Container";

interface PropsSection extends PropsWithChildren {
  className?: string;
  type?: "base" | "image" | "slider" | "video" | "block" | "map";
  backgroundImage?: CSSProperties["backgroundImage"];
  sliderImages?: CSSProperties["backgroundImage"][];
  video?: {
    poster?: HTMLVideoElement["poster"];
    source: HTMLSourceElement["src"];
    type: HTMLSourceElement["type"];
  };
  block?: {
    position?: "left" | "right";
    backgroundImage: CSSProperties["backgroundImage"];
  }
  map?: {
    position?: "left" | "right";
    coords: string;
    zoom?: string
  }
  withoutContainer?: boolean;
  container?: {};
  parallax?: PropsParallax;
};

export const Section = (props: PropsSection) => {
  const sectionId = useId();

  const containerClass = useMemo(() => {
    if (props.type === "image") {
      return "section-image";
    } else if (props.type === "slider") {
      return "section-slider";
    } else if (props.type === "video") {
      return "section-video";
    } else if (props.type === "block") {
      return `section-block section-block-full section-block-${props.block?.position || "right"}`;
    } else if (props.type === "map") {
      return `section-map section-map-${props.map?.position || "right"}`;
    }

    return "section-base";
  }, [props.type]);

  const withContainer = (children: ReactNode, removeContainer: boolean = false) => {
    if (props.withoutContainer || removeContainer) {
      return children;
    }

    return (
      <Container {...props.container}>
        {children}
      </Container>
    );
  }

  return (
    <section
      className={`${containerClass} ${props.className || ""}`}
      style={{
        backgroundImage: props.parallax ? "" : `url(${props.backgroundImage})`
      }}
    >
      {props.parallax && props.backgroundImage && (
        <Parallax
          speed={500}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%"
          }}
          translateY={[-50, 50]}
          translateX={[0, 0]}
          {...props.parallax}
          className={`${props.parallax.className} ${props.parallax.animation}`}
        >
          <img
            src={props.backgroundImage}
            height={"100%"}
            width={"100%"}
            style={{
              objectFit: "cover"
            }}
          />
        </Parallax>
      )}

      {props.type === "slider" && props.sliderImages && (
        <div className="background-slider">
          {props.sliderImages?.map((element, index) => {
            return (
              <div
                key={`${sectionId}-slider-image-${index}`}
                className={index === 0 ? "active" : ""}
                style={{
                  backgroundImage: `url(${element})`
                }}
              />
            );
          })}
        </div>
      )}

      {props.type === "video" && props.video && (
        <video autoPlay loop muted poster={props.video.poster}>
          <source src={props.video.source} type={props.video.type} />
        </video>
      )}

      {props.type === "block" && (
        <>
          <div className="block-media">
            <div className="image" style={{ backgroundImage: `url(${props.block?.backgroundImage})`}}></div>
          </div>
          
          <Container>
            <div className="row">
              <div className="col">
                <p>
                  {withContainer(props.children, true)}
                </p>
              </div>
            </div>
          </Container>
        </>
      )}

      {props.type === "map" && (
        <>
          <div
            className="google-map"
            data-coords={props.map?.coords}
            data-zoom={props.map?.zoom || "12"}
            data-marker-pos={props.map?.position || "right"}
          />

          <Container {...props.container}>
            <p>
              {withContainer(props.children, true)}
            </p>
          </Container>
        </>
      )}

      {props.type !== "block" && props.type !== "map" && withContainer(props.children)}
    </section>
  )
}