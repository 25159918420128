import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { IAssociation } from "interfaces/IAssociation";

export const AssociationService = {
  getAll: () => {
    return AxiosInstance.get<IAssociation[]>("associations").then((response) => {
      return response.data;
    });
  },

  getById: (id: number) => {
    return AxiosInstance.get<IAssociation>(`associations/${id}`).then(
      (response) => {
        return response.data;
      }
    );
  },

  create: (data: IAssociation) => {
    return AxiosInstance.post<IAssociation>("associations/create", data).then((response) => {
      return response.data;
    });
  },

  update: (data: IAssociation) => {
    return AxiosInstance.post<IAssociation>("associations/update", data).then((response) => {
      return response.data;
    });
  },

  delete: (id: number) => {
    return AxiosInstance.delete("associations/delete", {
      params: {
        id,
      },
    }).then((response) => {
      return response.data;
    });
  },

  uploadImageById: ({id, image}: {id: number, image: File}) => {
    const formData = new FormData();
    formData.append("image", image);
    return AxiosInstance.postForm("associations/image", formData, {
      params: {
        id,
      },
    }).then((response) => {
      return response.data;
    });
  },
};
