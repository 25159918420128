import { PropsWithChildren, useId } from "react";

interface PropsListItem {
  src: string;
  title?: string;
}

const ListItem = (props: PropsListItem) => {
  return (
    <div className="album-box">
      <a href="#" className="img-box img-scale">
        <img
          src={props.src}
          alt="notFound"
        />
      </a>
      
      {props.title && (
        <div className="caption">
          <h3>{props.title}</h3>
        </div>
      )}
    </div>
  )
}

const List = (props: PropsWithChildren) => {
  return (
    <div className="album-list">
      {props.children}
    </div>
  );
}
List.Item = ListItem;

interface PropsBoxItem {
  columns?: string;
  sources: string[];
}

const BoxItem = (props: PropsBoxItem) => {
  const boxItemId = useId();

  return (
    <div className="album-item">
      <div className="grid-list" data-columns={props.columns}>
        <div className="grid-box">
          {props.sources.map((element, index) => (
            <div
              key={`box-item-${boxItemId}-${index}`}
              className="grid-item"
            >
              <a className="img-box">
                <img src={element} alt="notFound" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

interface PropsBox extends PropsWithChildren {
  backLabel: string;
}

const Box = (props: PropsBox) => {
  return (
    <div className="cnt-album-box">
      <p className="album-title"><span>...</span> <a>{props.backLabel}</a></p>
      {props.children}
    </div>
  );
}
Box.Item = BoxItem;

interface PropsAlbum extends PropsWithChildren {

}

export const Album = (props: PropsAlbum) => {
  return (
    <div
      className="album"
      data-album-anima="fade-bottom"
      data-columns="2"
    >
      {props.children}
    </div>
  )
};
Album.List = List;
Album.Box = Box;