export const HomeSliderSettings = {
  slidesPerRow: 1,
  slidesToShow: 3,
  adaptiveHeight: true,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};