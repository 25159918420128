import Glide from "@glidejs/glide";
import { Controls } from "@glidejs/glide/dist/glide.modular.esm";
import React, { PropsWithChildren, useEffect, useMemo } from 'react';

interface PropsItem extends PropsWithChildren {

}

const Item = (props: PropsItem) => {
  return (
    <li className='glide__slide slider'>
      {props.children}
    </li>
  )
}

interface PropsSlider extends Partial<Glide.Options>, PropsWithChildren {
  id: string;
  slidesClassName?: string;
  hideArrows?: boolean;
  showNav?: boolean;
  controls?: {
    position: "right" | "left",
  }
}

export const Slider = (props: PropsSlider) => {

  const {hideArrows, ...glideOptions} = props;

  const options: Partial<Glide.Options> = {
    gap: 20,
    perView: 2,
    startAt: 0,
    type: "slider",
    ...glideOptions
  }

  useEffect(() => {
    new Glide(`#${props.id}`, options).mount();
  }, []);

  const controlsPosition = useMemo(() => {
    if (props.controls) {
      return " controls-right ";
    }
    return "";
  }, []);

  return (
    <div id={props.id} className={`glide controls-out ${controlsPosition}`}>
      <div className='glide__track' data-glide-el='track'>
          <ul className={`glide__slides ${props.slidesClassName}`}>
            {props.children}
          </ul>
      </div>
      
      {!props.hideArrows && (
        <div className="glide__arrows" data-glide-el="controls">
          <button type="button" className="glide__arrow glide__arrow--left" data-glide-dir="<"></button>
          <button type="button" className="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
        </div>
      )}

      {props.showNav && (
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {React.Children.map(props.children, (element, index) => {
            return (
              <button className="glide__bullet" data-glide-dir={`=${index}`}></button>
            )
          })}
        </div>
      )}
    </div>
  )
}
Slider.Item = Item;