import React from "react";
import * as Mui from "@mui/material";
import { Parallax } from "react-scroll-parallax";

type Props = {
  image: React.ImgHTMLAttributes<HTMLImageElement>["src"];
  animated?: boolean;
  className?: string;
  sx?:  Mui.SxProps<Mui.Theme>;
};

export function ParallaxContainer({
  image,
  className,
  sx,
  animated = false,
}: Props) {
  let classname = className || "";
  if (animated) {
    classname += " ken-burn-center ";
  }

  return (
    <Mui.Box
      position={"absolute"}
      height={"100%"}
      width={"100%"}
      sx={sx}
      zIndex={-1}
      maxWidth={'100vw !important'}
      overflow={'hidden'}
      top={0}
    >
      <Parallax
        speed={500}
        translateY={[-1, 1]}
        translateX={[0, 0]}
        className={classname}
        style={{
          top: 0,
          height:"100%"
        }}
      >
        <img
          src={image}
          height={"100%"}
          width={"100%"}
          style={{
            objectFit: "cover",
            top: 0,
          }}
        />
      </Parallax>
    </Mui.Box>
  );
}
