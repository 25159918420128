import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { useState } from "react";

type Props = {
  onEdit?: () => void;
  onDelete?: () => void;
};

const MoreActionsMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Mui.IconButton onClick={openMenu}>
        <MuiIcons.MoreVert />
      </Mui.IconButton>

      <Mui.Menu
        open={open}
        onClick={closeMenu}
        onClose={closeMenu}
        anchorEl={anchorEl}
      >
        {props.onEdit && (
          <Mui.MenuItem onClick={() => props.onEdit && props.onEdit()}>
            <Mui.IconButton>
              <MuiIcons.Edit />
            </Mui.IconButton>
          </Mui.MenuItem>
        )}

        {props.onDelete && (
          <Mui.MenuItem onClick={() => props.onDelete && props.onDelete()}>
            <Mui.IconButton>
              <MuiIcons.Delete />
            </Mui.IconButton>
          </Mui.MenuItem>
        )}
      </Mui.Menu>
    </>
  );
};

export default MoreActionsMenu;
