import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useState } from "react";
import { AlpinSettings } from "utils/AlpinSettings";
import enlogo from "../assets/media/en.png";
import eslogo from "../assets/media/es.png";
import itlogo from "../assets/media/it.png";

import { useAuthentication } from "hooks/useAuthentication";
import { useNavigate } from "react-router";
import { AuthenticationService } from "service/AuthenticationService";
import { AppBar } from "./AppBar";

const LogoutButton = (props: { sx?: Mui.ButtonProps["sx"] }) => {
  const isAuthenticated = useAuthentication();

  const onLogout = () => {
    AuthenticationService.logout().then(() => window.location.reload());
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Mui.ListItemButton onClick={onLogout} sx={props.sx}>
      Logout
    </Mui.ListItemButton>
  );
};

const LanguageButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Mui.ListItemButton onClick={handleClick}>
        <Mui.Typography variant="body1" color={{ xs: "#000", lg: "#fff" }}>
          <Mui.Box component="img" src={enlogo} /> EN
        </Mui.Typography>
      </Mui.ListItemButton>

      <Mui.Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Mui.MenuItem onClick={handleClose}>
          <Mui.Typography variant="body1">
            <Mui.Box component="img" src={itlogo} /> IT
          </Mui.Typography>
        </Mui.MenuItem>
        <Mui.MenuItem onClick={handleClose}>
          <Mui.Typography variant="body1">
            <Mui.Box component="img" src={eslogo} /> ES
          </Mui.Typography>
        </Mui.MenuItem>
      </Mui.Menu>
    </>
  );
};

export const TopBar = () => {
  const [openMob, setOpenMob] = useState(false);
  const handleClickMob = () => {
    setOpenMob(!openMob);
  };
  const handleCloseMob = () => {
    setOpenMob(false);
  };

  const navigate = useNavigate();

  return (
    <Mui.Box>
      <Mui.CssBaseline />
      <AppBar>
        <Mui.Container maxWidth="xl">
          <Mui.Toolbar sx={{ justifyContent: "space-between" }}>
            <Mui.Box display="flex" alignItems="center" gap={3}>
              <Mui.Button onClick={() => navigate("/")} sx={{ padding: 0 }}>
                <Mui.Box
                  component="img"
                  src="logo.jpg"
                  sx={{ width: "65px", height: AlpinSettings.topBar.height }}
                />
              </Mui.Button>
              <Mui.Button onClick={() => navigate("/")}>
                <Mui.Typography variant="h4" color="white">
                  FSHALTM
                </Mui.Typography>
              </Mui.Button>
            </Mui.Box>

            {/* Mobile menu */}
            <Mui.Box>
              <Mui.Button
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                  },
                  color: "white",
                }}
                onClick={handleClickMob}
              >
                <MuiIcons.Menu />
              </Mui.Button>

              <Mui.Drawer
                open={openMob}
                onClose={handleCloseMob}
                anchor="right"
              >
                <Mui.List
                  sx={{ pt: AlpinSettings.topBar.height, overflow: "auto" }}
                >
                  <Mui.ListItem>
                    <Mui.ListItemButton onClick={() => navigate("/")}>
                      INFORMACIONE
                    </Mui.ListItemButton>
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <Mui.ListItemButton onClick={() => navigate("/shoqata")}>
                      SHOQATA
                    </Mui.ListItemButton>
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <Mui.ListItemButton onClick={() => navigate("/guidat")}>
                      GUIDAT
                    </Mui.ListItemButton>
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <Mui.ListItemButton
                      onClick={() => navigate("/shpetimi-malor")}
                    >
                      SHPETIMI MALOR
                    </Mui.ListItemButton>
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <Mui.ListItemButton
                      onClick={() => navigate("/ngjitja-sportive")}
                    >
                      NGJITJA SPORTIVE
                    </Mui.ListItemButton>
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <Mui.ListItemButton onClick={() => navigate("/rreth-nesh")}>
                      RRETH NESH
                    </Mui.ListItemButton>
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <LanguageButton />
                  </Mui.ListItem>

                  <Mui.ListItem>
                    <LogoutButton />
                  </Mui.ListItem>
                </Mui.List>
              </Mui.Drawer>
            </Mui.Box>

            {/* Web Menu */}
            <Mui.Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
              }}
            >
              <Mui.Box sx={{ display: "flex" }} gap={3}>
                <Mui.Button
                  onClick={() => navigate("/")}
                  sx={{ color: "white" }}
                >
                  <Mui.Typography variant="body2">Informacione</Mui.Typography>
                </Mui.Button>

                <Mui.Button
                  onClick={() => navigate("/shoqata")}
                  sx={{ color: "white" }}
                >
                  <Mui.Typography variant="body2">Shoqata</Mui.Typography>
                </Mui.Button>

                <Mui.Button
                  onClick={() => navigate("/guidat")}
                  sx={{ color: "white" }}
                >
                  <Mui.Typography variant="body2">Guidat</Mui.Typography>
                </Mui.Button>

                <Mui.Button
                  onClick={() => navigate("/shpetimi-malor")}
                  sx={{ color: "white" }}
                >
                  <Mui.Typography variant="body2">
                    Shpetimi malor
                  </Mui.Typography>
                </Mui.Button>

                <Mui.Button
                  onClick={() => navigate("ngjitja-sportive")}
                  sx={{ color: "white" }}
                >
                  <Mui.Typography variant="body2">
                    Ngjitja Sportive
                  </Mui.Typography>
                </Mui.Button>

                <Mui.Button
                  onClick={() => navigate("rreth-nesh")}
                  sx={{ color: "white" }}
                >
                  <Mui.Typography variant="body2">Rreth Nesh</Mui.Typography>
                </Mui.Button>

                <LanguageButton />

                <LogoutButton sx={{ ml: "auto", color: "white" }} />
              </Mui.Box>
            </Mui.Box>
          </Mui.Toolbar>
        </Mui.Container>
      </AppBar>
    </Mui.Box>
  );
};
