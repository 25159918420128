import * as Mui from "@mui/material";
import { useAuthentication } from "hooks/useAuthentication";
import { ITranslation, ITranslationObj } from "interfaces/ITranslation";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { translationsService } from "service/TranslationsService";

type Props = {
  id: string;
  section: string;
  renderer?: (label?: string) => React.ReactElement;
  defaultLabel: string;
  translations: ITranslationObj;
  onChangeFinished?: () => void;
};

const LabelEditor = (props: Props & PropsWithChildren) => {
  const isAuthenticated = useAuthentication();

  const translationLabel = useMemo(
    () => props.translations[props.id]?.al || props.defaultLabel,
    [props.translations]
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [label, setLabel] = useState(
    props.translations[props.id]?.al || props.defaultLabel
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLabel = () => {
    translationsService
      .upsert({
        id: props.id,
        section: props.section,
        al: label,
        // TODO: To change into the english change value
        en: props.translations[props.id]?.en,
      })
      .then(() => {
        setAnchorEl(null);
        props.onChangeFinished && props.onChangeFinished();
      });
  };

  const isOpen = Boolean(anchorEl);

  if (isAuthenticated) {
    return (
      <>
        <Mui.Popover
          open={isOpen}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Mui.Stack direction={"row"} alignItems={"center"}>
            <Mui.TextField
              multiline={true}
              value={label}
              onChange={(event) => setLabel(event.currentTarget.value)}
              fullWidth
            />

            <Mui.Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={onChangeLabel}
              sx={{
                margin: 2,
                padding: 2,
              }}
            >
              Change
            </Mui.Button>
          </Mui.Stack>
        </Mui.Popover>

        <Mui.Box onClick={handleClick}>
          {props.renderer && props.renderer(translationLabel)}
        </Mui.Box>
      </>
    );
  }

  return props.renderer && props.renderer(translationLabel);
};

export default LabelEditor;
