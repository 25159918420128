import * as Mui from "@mui/material";
import ImageSkeleton from "components/ImageSkeleton";
import MoreActionsMenu from "components/MoreActionsMenu";
import { useAuthentication } from "hooks/useAuthentication";
import { IMembers } from "interfaces/IMembers";

interface Props {
  member: IMembers;
  onClickItem?: (id: number) => void;
  onEditItem?: (id: number) => void;
  onDeleteItem?: (id: number) => void;
}

const MemberCard = (props: Props) => {
  const isAuthenticated = useAuthentication();

  const onClickItem = () => {
    if (props.onClickItem) {
      props.onClickItem(props.member.id);
    }
  };

  return (
    <Mui.Box
      sx={{
        ":hover": {
          backgroundColor: "#c0e2fc",
        },
        borderRadius: "8px",
        padding: 4,
      }}
    >
      <Mui.Stack direction={"row"} alignItems={"center"}>
        <Mui.Grid
          container
          sx={{
            ":hover": {
              cursor: "pointer",
              color: "darkblue",
            },
          }}
          spacing={2}
          alignItems={"center"}
          onClick={() => onClickItem()}
        >
          <Mui.Grid item xs={4} md={2}>
            <Mui.Box
              borderRadius={"50%"}
              width={"100%"}
              height={"100%"}
              overflow={"hidden"}
              sx={{
                aspectRatio: "1/1",
              }}
            >
              <ImageSkeleton src={props.member.image} variant="circular" />
            </Mui.Box>
          </Mui.Grid>

          <Mui.Grid item xs={8} md={10}>
            <Mui.Typography variant="h3">{props.member.name}</Mui.Typography>

            <Mui.Box>
              <Mui.Stack>
                <Mui.Typography variant="h5">
                  {props.member.type}
                </Mui.Typography>

                <Mui.Typography>{props.member.categorization}</Mui.Typography>
              </Mui.Stack>
            </Mui.Box>
          </Mui.Grid>
        </Mui.Grid>

        {isAuthenticated && (
          <MoreActionsMenu
            onEdit={() => props.onEditItem && props.onEditItem(props.member.id)}
            onDelete={() =>
              props.onDeleteItem && props.onDeleteItem(props.member.id)
            }
          />
        )}
      </Mui.Stack>
    </Mui.Box>
  );
};

export default MemberCard;
