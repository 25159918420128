import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { IPositives } from "interfaces/IPositives";

export const PositivesServices = {
  getAll: () => {
    return AxiosInstance.get<IPositives[]>("positives").then((response) => {
      return response.data;
    });
  },
};
