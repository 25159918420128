import * as Mui from "@mui/material";
import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { useEffect, useState } from "react";

import { Buffer } from "buffer";

type Props = {
  src?: HTMLImageElement["src"];
  variant?: Mui.SkeletonProps["variant"];
  boxProps?: Mui.BoxProps;
  skeletonProps?: Mui.SkeletonProps;
};

const ImageSkeleton = (props: Props) => {
  const [image, setImage] = useState<any>();

  const circleSettings = {
    borderRadius: "50%",
    sx: {
      aspectRatio: "1/1",
    },
    overflow: "hidden",
  };

  const loadImage = (): void => {
    console.log("props.src: ", props.src);
    if (props.src) {
      AxiosInstance.get(`${process.env.REACT_APP_IMAGE_URL}/${props.src}`, {
        responseType: "arraybuffer",
      })
        .then((response) => {
          const imgtosave = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setImage(imgtosave);
        })
        .catch((error) => {
          setImage(undefined);
        });
    }
  };

  useEffect(loadImage, [props.src]);

  return (
    <Mui.Box
      width={"100%"}
      height={"100%"}
      {...(props.variant === "circular" && circleSettings)}
      {...props.boxProps}
    >
      {props.src && image ? (
        <img
          src={`data:image/png;base64, ${image}`}
          alt="image"
          width="100%"
          height={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
          loading="lazy"
        />
      ) : (
        <Mui.Skeleton
          variant={props.variant}
          width={"100%"}
          height={"100%"}
          {...props.skeletonProps}
          sx={{
            transform: "none",
            ...props.skeletonProps?.sx,
          }}
        />
      )}
    </Mui.Box>
  );
};

export default ImageSkeleton;
