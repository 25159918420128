import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { useState } from "react";
import { ImageType } from "./useImageUpload";

const VisuallyHiddenInput = Mui.styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type Props = {
  image: ImageType;
  onUpload: (image: ImageType) => void;
};

const ImageUpload = (props: Props) => {
  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    props.onUpload(files?.item(0));
  };

  return (
    <Mui.Stack direction={"row"} alignItems={"center"} spacing={2}>
      <Mui.Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<MuiIcons.Image />}
        color="info"
      >
        Upload Image
        <VisuallyHiddenInput
          type="file"
          accept="image/png, image/jpeg"
          onChange={onChangeImage}
        />
      </Mui.Button>

      {props.image?.name && (
        <Mui.Typography variant="caption">{props.image?.name}</Mui.Typography>
      )}

      {props.image && (
        <Mui.IconButton onClick={() => props.onUpload(null)}>
          <MuiIcons.Delete />
        </Mui.IconButton>
      )}
    </Mui.Stack>
  );
};

export default ImageUpload;
