import * as Mui from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { IAuthentication } from "interfaces/IAuthentication";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthenticationService } from "service/AuthenticationService";

type Props = {};

const Authentication = (props: Props) => {
  const navigate = useNavigate();

  const form = useForm<IAuthentication>();

  const login = useMutation({
    mutationFn: AuthenticationService.login,
    onSuccess: () => navigate("/"),
  });

  const onSubmit = (data: IAuthentication) => {
    login.mutate(data);
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Mui.Stack
        width={{
          xs: "90vw",
          md: "70vw",
          lg: "50vw",
        }}
        mx="auto"
        my={40}
        spacing={5}
      >
        <Mui.TextField
          variant="standard"
          label="username"
          {...form.register("username")}
        />

        <Mui.TextField
          variant="standard"
          label="username"
          type="password"
          {...form.register("password")}
        />

        <Mui.Stack direction={"row"} justifyContent={"center"}>
          <Mui.Button type="submit" variant="contained" size="large">
            Login
          </Mui.Button>
        </Mui.Stack>
      </Mui.Stack>
    </form>
  );
};

export default Authentication;
