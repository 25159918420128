import * as Mui from "@mui/material";
import image2 from "assets/fshaltm/mountain-landscape-2031539_1280.jpg";
import { PageHeader } from "components/PageHeader";
import { ParallaxContainer } from "components/ParallaxContainer";
import { Plus } from "components/Plus";
import { IAssociation } from "interfaces/IAssociation";
import { Positives } from "pages/components/Positives";
import { useEffect, useState } from "react";
import { AssociationService } from "service/AssociationService";
import { alpinTheme } from "theme/AlpinTheme";
import AssociationItem from "./AssociationItem";
import { ManagePopup } from "./ManagePopup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "service/tanstack";
import { useAuthentication } from "hooks/useAuthentication";

interface Props {}

const Associations = (props: Props) => {
  const queryClient = useQueryClient();

  const isAuthenticated = useAuthentication();

  const [openPopup, setopenPopup] = useState<boolean>(false);
  const [shoqataEdit, setshoqataEdit] = useState<IAssociation>();

  const associations = useQuery({
    queryKey: [queryKeys.assocaitions.getall],
    queryFn: AssociationService.getAll,
  });

  const updateShoqata = (shoqata: IAssociation) => {
    setshoqataEdit(shoqata);
    setopenPopup(true);
  };

  const onClosePopUp = () => {
    setshoqataEdit(undefined);
    setopenPopup(false);
  };

  const remove = useMutation({
    mutationFn: AssociationService.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.assocaitions.getall],
      });
    },
  });

  const deleteShoqata = (id: number) => {
    remove.mutate(id);
  };

  return (
    <>
      <PageHeader
        title="Shoqata"
        subtitle="Shoqatat ne shqiperi"
        parallax={<ParallaxContainer image={image2} animated />}
        breadcrumbs={[
          {
            title: "home",
            to: "/",
          },
          {
            title: "shoqata",
          },
        ]}
      />

      <Mui.Stack
        sx={{
          background: alpinTheme.palette.background.default,
        }}
      >
        <Mui.Box
          sx={{
            px: {
              xs: 5,
              md: 5,
              lg: 15,
            },
            py: {
              xs: 5,
              md: 10,
              lg: 15,
            },
          }}
        >
          <Mui.Grid container spacing={4}>
            {associations.data?.map((association, i) => {
              return (
                <AssociationItem
                  association={association}
                  update={() => updateShoqata(association)}
                  delete={() => deleteShoqata(association.id)}
                />
              );
            })}

            {isAuthenticated && (
              <Mui.Grid item xs={12} sm={6} md={3}>
                <Plus buttonOnClick={() => setopenPopup(true)} />
              </Mui.Grid>
            )}
          </Mui.Grid>
        </Mui.Box>

        <Mui.Box
          sx={{
            px: {
              xs: 5,
              md: 5,
              lg: 15,
            },
            py: {
              xs: 5,
              md: 10,
              lg: 15,
            },
          }}
          bgcolor="background.paper"
        >
          <Positives />
        </Mui.Box>
      </Mui.Stack>

      <ManagePopup
        onClose={() => onClosePopUp()}
        open={openPopup}
        shoqata={shoqataEdit}
      />
    </>
  );
};

export default Associations;
