import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";

export const AlpinFooter = () => {
  return (
    <Mui.Box
      sx={{
        px: {
          xs: 5,
          md: 5,
          lg: 15,
        },
        py: {
          xs: 5,
        },
      }}
      bgcolor={"#13304a"}
    >
      <Mui.Grid
        container
        style={{
          color: "white",
          display: "flex",
        }}
        spacing={5}
      >
        {/* <Mui.Container maxWidth="lg" sx={{display:"flex", alignItems:"baseline"}}> */}
        <Mui.Grid item sm={4} xs={12}>
          <Mui.Typography>Alpins</Mui.Typography>
          <Mui.Typography>
            Somewhere between the bottom of the climb and the summit is the
            answer to the mystery why we climb.
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item sm={4} xs={12}>
          <Mui.Typography>Contacts</Mui.Typography>
          {/* <Mui.List>
                <Mui.ListItem>
                    San Pellegrino, BG, Italy
                </Mui.ListItem>
                <Mui.ListItem>
                    hello@example.com
                </Mui.ListItem>
                <Mui.ListItem>
                    02 123 333 444
                </Mui.ListItem>
                </Mui.List> */}
          <Mui.Typography>
            <Mui.Link sx={{ color: "white", textDecorationColor: "white" }}>
              San Pellegrino, BG, Italy
            </Mui.Link>
          </Mui.Typography>
          <Mui.Typography>
            <Mui.Link sx={{ color: "white", textDecorationColor: "white" }}>
              hello@example.com
            </Mui.Link>
          </Mui.Typography>
          <Mui.Typography>
            <Mui.Link sx={{ color: "white", textDecorationColor: "white" }}>
              02 123 333 444
            </Mui.Link>
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item sm={4} xs={12}>
          <Mui.ButtonGroup>
            <Mui.IconButton color="primary">
              <MuiIcons.Facebook />
            </Mui.IconButton>
            <Mui.IconButton color="inherit">
              <MuiIcons.Instagram />
            </Mui.IconButton>
            <Mui.IconButton color="default">
              <MuiIcons.X />
            </Mui.IconButton>
            <Mui.IconButton color="error">
              <MuiIcons.Google />
            </Mui.IconButton>
          </Mui.ButtonGroup>
          <Mui.Typography>
            Subscribe to our newsletter of follow us on the social channels to
            stay tuned.
          </Mui.Typography>
        </Mui.Grid>

        {/* <Mui.Grid item xs={12} display={"flex"} justifyContent={"end"}>
          <Mui.Button
            variant="text"
            size="small"
            onClick={() => {}}
            sx={{ color: "white" }}
          >
            Login
          </Mui.Button>
        </Mui.Grid> */}
      </Mui.Grid>
    </Mui.Box>
  );
};
