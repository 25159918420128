import * as Mui from "@mui/material";

type Props = {
  open: boolean;
};

const Loader = (props: Props) => {
  return (
    <Mui.Backdrop open={props.open}>
      <Mui.CircularProgress />
    </Mui.Backdrop>
  )
};

export default Loader;
