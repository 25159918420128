import { useEffect, useState } from "react";
import { AuthenticationService } from "service/AuthenticationService";

export const useAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsAuthenticated(false);
    } else {
      AuthenticationService.check(token).then((response) => {
        setIsAuthenticated(response);
      });
    }
  };

  useEffect(checkToken, []);

  return isAuthenticated;
};
