import * as Mui from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import image_1 from "assets/fshaltm/istockphoto-1288385045-612x612.jpg";
import { PageHeader } from "components/PageHeader";
import { ParallaxContainer } from "components/ParallaxContainer";
import { AboutusService } from "service/AboutusService";
import { queryKeys } from "service/tanstack";
import AboutUsItem from "./AboutUsItem";
import { useAuthentication } from "hooks/useAuthentication";
import { Plus } from "components/Plus";
import { useState } from "react";
import AboutUsForm from "./AboutUsForm";
import { IAboutus } from "interfaces/IAboutus";

type Props = {};

export const AboutUs = (props: Props) => {
  const queryClient = useQueryClient();

  const isAuthenticated = useAuthentication();

  const [openForm, setOpenForm] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState<IAboutus | undefined>();

  const info = useQuery({
    queryKey: [queryKeys.aboutus.getall],
    queryFn: AboutusService.getAll,
  });

  const onEdit = (info: IAboutus) => {
    setSelectedInfo(info);
    setOpenForm(true);
  };

  const onClose = () => {
    setSelectedInfo(undefined);
    setOpenForm(false);
  };

  const remove = useMutation({
    mutationFn: AboutusService.remove,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.aboutus.getall],
      });
    },
  });

  return (
    <>
      <PageHeader
        title="Rreth Nesh"
        subtitle="118 vite alpinizëm shqiptar: nga maja e Tomorrit në majën Everest"
        breadcrumbs={[
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Rreth Nesh",
          },
        ]}
        parallax={<ParallaxContainer image={image_1} animated />}
      />

      <Mui.Container>
        <Mui.Stack sx={{ p: 2 }} spacing={4}>
          <Mui.Typography variant="h4">
            Sporti i alpinizmit u praktikua në Shqipëri për herë të parë rreth
            120 vjet pas shpikjes së tij, u zhvillua profesionalisht gjatë
            periudhës së komunizmit, dhe njohu majat më të larta të rruzullit
            pas hapjes së kufijve me ardhjen e demokracisë.
          </Mui.Typography>

          {info.data?.map((info, index) => (
            <AboutUsItem
              key={`aboutus-${index}-${info.title}`}
              item={info}
              onEdit={() => onEdit(info)}
              onDelete={() => remove.mutate(info.id)}
            />
          ))}

          {isAuthenticated && <Plus buttonOnClick={() => setOpenForm(true)} />}
        </Mui.Stack>
      </Mui.Container>

      <AboutUsForm onClose={onClose} open={openForm} data={selectedInfo} />
    </>
  );
};
