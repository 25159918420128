import {
  Palette,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    containedFilled: true;
    outlinedFilled: true;
  }
}

const paletteOptions: Palette & {
  primary: Palette["primary"] & {
    "100"?: string;
    "200"?: string;
    "300"?: string;
  };
  text: Palette["text"] & {
    headerTitle?: string;
    headerBreadcrumbs?: string;
  };
} = createPalette({
  mode: "light",
  primary: {
    main: "#379cf4",
    "100": "#13304a",
    "200": "#53626F",
    "300": "#379cf4",
    "400": "#53626f",
  },
  text: {
  //   primary: "#000",
    secondary: "#000",
  },
  background: {
    paper: "#eef3f7",
    default: "#ffffff",
  },
});

const typographyOptions = createTypography(paletteOptions, {
  allVariants: {
    fontFamily: "Montserrat, sans-serif",
  },
  body1: {
    lineHeight: "30px",
    fontSize: "14px",
    fontWeight: "500",
  },
  body2: {
    lineHeight: "30px",
    fontSize: "14px",
    fontWeight: "700",
  },
  h1: {
    fontSize: "65px",
    fontWeight: "900",
  },
  h2: {
    fontSize: "55px",
  },
  h3: {
    fontSize: "35px",
  },
  h4: {
    fontSize: "30px",
    fontWeight: "800",
    letterSpacing: ".5px",
  },
  h5: {
    fontSize: "22px",
    fontWeight: "700",
  },
  h6: {
    fontSize: "17px",
    fontWeight: "700",
  },
});

const themeOptions: ThemeOptions = {
  palette: paletteOptions,
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: "containedFilled",
          },
          style: {
            borderRadius: "100px",
            border: "1px solid",
            backgroundColor: paletteOptions.primary[300],
            borderColor: paletteOptions.primary[300],
            ":hover": {
              backgroundColor: paletteOptions.primary[300],
            },
            color: "white",
            padding: "10px 30px",
            textTransform: "none",
          },
        },
        {
          props: {
            variant: "outlinedFilled",
          },
          style: {
            borderRadius: "100px",
            border: "1px solid",
            borderColor: paletteOptions.primary[300],
            ":hover": {
              borderColor: paletteOptions.primary[300],
            },
            color: paletteOptions.primary[300],
            padding: "10px 30px",
          },
        },
      ],
    },
  },
  typography: typographyOptions,
};

let alpinTheme = createTheme(themeOptions);
alpinTheme = responsiveFontSizes(alpinTheme);
export { alpinTheme };
