import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { IEvents } from "interfaces/IEvents";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { HomeSliderSettings } from "./HomeSliderSettings";
import ImageSkeleton from "components/ImageSkeleton";
import { QuoteChip } from "components";
import { EventsService } from "service/EventsService";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "service/tanstack";
import { ITranslationObj } from "interfaces/ITranslation";
import { translationsService } from "service/TranslationsService";
import LabelEditor from "components/LabelEditor";

type Props = {};

const HomeEvents = (props: Props) => {
  const navigate = useNavigate();

  const events = useQuery({
    queryKey: [queryKeys.events.getLatest],
    queryFn: EventsService.getLatest,
  });

  const [translations, setTranslations] = useState<ITranslationObj>({});

  const section = "home.events";

  const loadTranslations = () => {
    translationsService
      .getBySection(section)
      .then((response) => setTranslations(response));
  };

  useEffect(loadTranslations, []);

  return (
    <Mui.Box
      sx={{
        px: {
          xs: 5,
          md: 5,
          lg: 15,
        },
        py: {
          xs: 5,
          md: 10,
          lg: 15,
        },
        background: "white",
      }}
    >
      <Mui.Grid container spacing={3}>
        <Mui.Grid item xs={12} md={6}>
          <Slider {...HomeSliderSettings} adaptiveHeight={false}>
            {events.data?.map((event, index) => {
              return (
                <Mui.Stack
                  key={`event-${event.title}-${index}`}
                  px={1}
                  height={"200px"}
                  spacing={5}
                >
                  <ImageSkeleton src={event.image} />

                  <Mui.Typography color="primary.400" whiteSpace={"pre-line"}>
                    {event.description}
                  </Mui.Typography>

                  <Mui.Box
                    display="flex"
                    color="primary.main"
                    flexWrap={"wrap"}
                  >
                    {event.tags &&
                      event.tags.split(",").map((tag) => (
                        <>
                          <MuiIcons.Tag />
                          <Mui.Typography>{tag}</Mui.Typography>
                        </>
                      ))}
                  </Mui.Box>
                </Mui.Stack>
              );
            })}
          </Slider>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={6}>
          <Mui.Stack spacing={5}>
            <LabelEditor
              id="title"
              section={section}
              translations={translations}
              onChangeFinished={loadTranslations}
              defaultLabel="EVENTET E FUNDIT"
              renderer={(label) => (
                <Mui.Typography variant="h4" color="black">
                  {label}
                </Mui.Typography>
              )}
            />

            <LabelEditor
              id="subtitle"
              section={section}
              translations={translations}
              onChangeFinished={loadTranslations}
              defaultLabel="Eksploro nje bote te pa eksploruar"
              renderer={(label) => (
                <Mui.Typography variant="body1">
                  {label} <QuoteChip />
                </Mui.Typography>
              )}
            />

            <LabelEditor
              id="description"
              section={section}
              translations={translations}
              onChangeFinished={loadTranslations}
              defaultLabel="Lorem ipsum dolor sit amet consectetur adipiscing elitsed do
              eiusmod tempor incididunt utlabore et dolore magna aliqua. Utenim
              ad minim veniam quiso."
              renderer={(label) => <Mui.Typography>{label}</Mui.Typography>}
            />

            <Mui.Button
              variant="containedFilled"
              sx={{ width: "fit-content" }}
              onClick={() => navigate("eventet")}
            >
              Shiko me shume
            </Mui.Button>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Box>
  );
};

export default HomeEvents;
