import * as Mui from "@mui/material";
import { useForm } from "react-hook-form";
import { ChangeEventHandler, useEffect, useState } from "react";
import { alpinTheme } from "theme/AlpinTheme";
import * as MuiIcons from "@mui/icons-material";
import { AssociationService } from "service/AssociationService";
import { IAssociation } from "interfaces/IAssociation";
import { useImageUpload } from "components/imageUpload/useImageUpload";
import ImageUpload from "components/imageUpload/ImageUpload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "service/tanstack";

type Props = {
  shoqata?: IAssociation;
  open: boolean;
  onClose?: (value: boolean) => void;
};

type FormValues = {
  id: number;
  name: string;
  description: string;
  image: string;
};

export const ManagePopup = (props: Props) => {
  const queryClient = useQueryClient();

  const { register, handleSubmit, reset } = useForm<FormValues>();

  const { image, setImage } = useImageUpload();

  useEffect(() => {
    if (props.open) {
      reset(props.shoqata);
    }
  }, [props.open]);

  const handleClose = () => {
    props.onClose ? props.onClose(!props.open) : null;
    reset({});
    setImage(undefined);

    queryClient.invalidateQueries({
      queryKey: [queryKeys.assocaitions.getall],
    });
    queryClient.invalidateQueries({
      queryKey: [queryKeys.assocaitions.getbyid],
    });
  };

  const upload = useMutation({
    mutationFn: AssociationService.uploadImageById,
    onSuccess: handleClose,
  });

  const create = useMutation({
    mutationFn: AssociationService.create,
    onSuccess: (data) => {
      if (image) {
        upload.mutate({ id: data.id, image });
      } else {
        handleClose();
      }
    },
  });

  const update = useMutation({
    mutationFn: AssociationService.update,
    onSuccess: (data) => {
      if (image) {
        upload.mutate({ id: data.id, image });
      } else {
        handleClose();
      }
    },
  });

  const onSubmit = (data: FormValues) => {
    if (!props.shoqata) {
      create.mutate(data);
    } else {
      update.mutate(data);
    }
  };

  return (
    <Mui.Dialog onClose={handleClose} open={props.open} fullWidth maxWidth="lg">
      <Mui.DialogTitle>
        <Mui.Stack direction={"row"} justifyContent={"space-between"}>
          <Mui.Typography variant="h4">
            {`Manage Shoqata: ${props.shoqata?.name ? props.shoqata.name : ""}`}
          </Mui.Typography>

          <Mui.IconButton onClick={handleClose}>
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Stack>
      </Mui.DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.DialogContent>
          <Mui.Grid container spacing={3} columns={1}>
            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                label="Name"
                type="name"
                autoComplete="current-name"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("name")}
                required
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                label="Description"
                type="description"
                autoComplete="current-description"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("description")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <ImageUpload image={image} onUpload={setImage} />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.DialogContent>

        <Mui.DialogActions>
          <Mui.Button type="submit">Save</Mui.Button>
        </Mui.DialogActions>
      </form>
    </Mui.Dialog>
  );
};
