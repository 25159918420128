const getall = (param: string) => {
  return `${param}-get-all`;
};
const getbyid = (param: string) => {
  return `${param}-get-by-id`;
};
const getlatest = (param: string) => {
  return `${param}-get-latest`;
};
const create = (param: string) => {
  return `${param}-create`;
};
const update = (param: string) => {
  return `${param}-update`;
};
const deletebyid = (param: string) => {
  return `${param}-delete-by-id`;
};
const upload = (param: string) => {
  return `${param}-upload`;
};

const associations = "associations",
  members = "members",
  events = "events",
  positives = "positives",
  aboutus = "aboutus";

export const queryKeys = {
  assocaitions: {
    getall: getall(associations),
    getbyid: getbyid(associations),
  },
  members: {
    getall: getall(members),
    getbyid: getbyid(members),
    getGuidat: `${members}-get-guidat`,
    getShpetimiMalor: `${members}-get-shpetimi-malor`,
    getNgjitjeSportive: `${members}-get-ngjitje-sportive`,
  },
  events: {
    getall: getall(events),
    getbyid: getbyid(events),
    getLatest: getlatest(events),
  },
  positives: {
    getall: getall(positives),
    getbyid: getbyid(positives),
  },
  aboutus: {
    getall: getall(aboutus),
  },
};

export const mutationKeys = {};
