import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { QuoteChip } from "components";
import { dataAboutus } from "pages/data/aboutus";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AboutusService } from "service/AboutusService";
import { queryKeys } from "service/tanstack";
import { alpinTheme } from "theme/AlpinTheme";

type Props = {};

const HomeAboutUs = (props: Props) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string | false>(false);

  const info = useQuery({
    queryKey: [queryKeys.aboutus.getall],
    queryFn: AboutusService.getAll,
  });

  const handleChange = (panel: string) => {
    if (panel === expanded) {
      setExpanded(false);
    } else {
      setExpanded(panel);
    }
  };

  return (
    <Mui.Box
      sx={{
        px: {
          xs: 5,
          md: 5,
          lg: 15,
        },
        py: {
          xs: 5,
          md: 10,
          lg: 15,
        },
        background: alpinTheme.palette.background.paper,
      }}
    >
      <Mui.Stack spacing={3}>
        <Mui.Typography variant="h4" color="text.primary" textAlign="center">
          RRETH NESH
        </Mui.Typography>
        <Mui.Typography textAlign="center">
          <QuoteChip />
          118 vite alpinizëm shqiptar: nga maja e Tomorrit në majën Everest{" "}
          <QuoteChip />
        </Mui.Typography>

        <Mui.Grid container gap={2}>
          <Mui.Grid item xs={12} sm={12} md={3}>
            <Mui.Typography variant="body1">
              {info.data?.[0].description.substring(0, 254)}
            </Mui.Typography>
          </Mui.Grid>

          <Mui.Grid item xs={12} sm={12} md={3}>
            <Mui.Typography variant="body1">
              {info.data?.[0].description.substring(254, 490)}
            </Mui.Typography>
            <Mui.Button
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={() => navigate("rreth-nesh")}
            >
              Read more <MuiIcons.KeyboardArrowRight />
            </Mui.Button>
          </Mui.Grid>

          <Mui.Grid item xs={12} sm={12} md={4}>
            {info.data?.map((element, index) => {
              return (
                <Mui.Accordion
                  sx={{
                    boxShadow: "0 3px 23px rgba(39,79,117,.15)",
                    borderTop: "1px solid rgba(83, 98, 111, .3)",
                    borderBottom: "1px solid rgba(83, 98, 111, .3)",
                    background: "rgb(255, 255, 255)",
                    padding: "5px 20px 20px 20px",
                    borderRadius: "3px",
                    borderColor: "rgb(255, 255, 255)",
                  }}
                  expanded={expanded === `panel-${index}`}
                  onChange={() => handleChange(`panel-${index}`)}
                >
                  <Mui.AccordionSummary
                    expandIcon={
                      <MuiIcons.ArrowForwardIosSharp
                        sx={{ fontSize: "1rem", color: "primary.main" }}
                      />
                    }
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Mui.Typography variant="body2">
                      {element.title}
                    </Mui.Typography>
                  </Mui.AccordionSummary>
                  <Mui.AccordionDetails>
                    <Mui.Typography variant="body1">
                      {element.description.substring(0, 500)}
                    </Mui.Typography>
                    <Mui.Button
                      variant="text"
                      sx={{ textTransform: "none" }}
                      onClick={() => navigate("rreth-nesh")}
                    >
                      Read more <MuiIcons.KeyboardArrowRight />
                    </Mui.Button>
                  </Mui.AccordionDetails>
                </Mui.Accordion>
              );
            })}
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Stack>
    </Mui.Box>
  );
};

export default HomeAboutUs;
