import * as Mui from "@mui/material";
import image2 from "assets/fshaltm/download (1).jpg";
import image3 from "assets/fshaltm/download.jpg";
import image4 from "assets/fshaltm/istockphoto-1288385045-612x612.jpg";
import image1 from "assets/fshaltm/photo-1589802829985-817e51171b92.webp";
import { QuoteChip } from "components";
import { ParallaxContainer } from "components/ParallaxContainer";
import CountUp from "react-countup";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Slider from "react-slick";
import { AlpinSettings } from "utils/AlpinSettings";
import { HomeSliderSettings } from "./HomeSliderSettings";
import LabelEditor from "components/LabelEditor";
import { useEffect, useMemo, useState } from "react";
import { translationsService } from "service/TranslationsService";
import { ITranslation, ITranslationObj } from "interfaces/ITranslation";

type Props = {};

const HomeBanner = (props: Props) => {
  const [translations, setTranslations] = useState<ITranslationObj>({});

  const section = "home.banner";

  const loadTranslations = () => {
    translationsService
      .getBySection(section)
      .then((response) => setTranslations(response));
  };

  useEffect(loadTranslations, []);

  return (
    <Mui.Box position="relative">
      <ParallaxContainer
        image={image1}
        animated
        sx={{ my: `-${AlpinSettings.topBar.height}` }}
      />
      <Mui.Grid
        container
        spacing={2}
        sx={{
          px: {
            xs: 5,
            md: 5,
            lg: 15,
          },
          py: {
            xs: 5,
            md: 10,
            lg: 15,
          },
        }}
      >
        <Mui.Grid item xs={12} sm={12} md={12} lg={6}>
          <LabelEditor
            id="title"
            section={section}
            defaultLabel="FEDERATA SHQIPTARE E ALPINIZMIT DHE TURIZMIT MALOR"
            renderer={(label) => (
              <Mui.Typography variant="h1" color={"white"}>
                {label}
              </Mui.Typography>
            )}
            translations={translations}
            onChangeFinished={() => loadTranslations()}
          />

          <Mui.Box mt={16}>
            <Slider
              autoplay
              arrows={false}
              slidesToShow={1}
              slidesToScroll={1}
              speed={1000}
              autoplaySpeed={5000}
              dots
            >
              <Mui.Stack spacing={4}>
                <LabelEditor
                  id="saying-1"
                  section={section}
                  translations={translations}
                  defaultLabel="Do not follow where the path may lead. Go instead where there
                  is no path and leave a trail."
                  renderer={(label) => (
                    <Mui.Typography variant="body1" color={"white"}>
                      {label}
                    </Mui.Typography>
                  )}
                  onChangeFinished={loadTranslations}
                />

                <LabelEditor
                  id="saying-1-author"
                  section={section}
                  translations={translations}
                  onChangeFinished={loadTranslations}
                  defaultLabel="Ralph Emerson"
                  renderer={(label) => (
                    <Mui.Typography variant="h4" color={"white"}>
                      {label} <QuoteChip />
                    </Mui.Typography>
                  )}
                />
              </Mui.Stack>

              <Mui.Stack spacing={4}>
                <LabelEditor
                  id="saying-2"
                  section={section}
                  translations={translations}
                  onChangeFinished={loadTranslations}
                  defaultLabel="When everything feels like an uphill struggle. Just wait and
                  think of the view from the top."
                  renderer={(label) => (
                    <Mui.Typography variant="body1" color={"white"}>
                      {label}
                    </Mui.Typography>
                  )}
                />

                <LabelEditor
                  id="saying-2-author"
                  section={section}
                  translations={translations}
                  onChangeFinished={loadTranslations}
                  defaultLabel="Wislawa Symborska"
                  renderer={(label) => (
                    <Mui.Typography variant="h4" color={"white"}>
                      {label} <QuoteChip />
                    </Mui.Typography>
                  )}
                />
              </Mui.Stack>
            </Slider>
          </Mui.Box>
        </Mui.Grid>

        <Mui.Grid item xs={12} sm={12} md={12} lg={6}>
          <Mui.Stack spacing={8}>
            <Mui.Box>
              <LabelEditor
                id="info-1-label"
                section={section}
                translations={translations}
                onChangeFinished={loadTranslations}
                defaultLabel="Mali me i larte"
                renderer={(label) => (
                  <Mui.Typography variant="body1" color={"white"}>
                    {label}
                  </Mui.Typography>
                )}
              />

              <LabelEditor
                id="info-1-value"
                section={section}
                translations={translations}
                onChangeFinished={loadTranslations}
                defaultLabel="2764"
                renderer={(label) => (
                  <Mui.Typography variant="h5" color={"white"}>
                    <CountUp
                      end={Number(label)}
                      duration={20}
                      startVal={0}
                      suffix=" m"
                    />
                  </Mui.Typography>
                )}
              />
            </Mui.Box>

            <Mui.Box>
              <LabelEditor
                id="info-2-label"
                section={section}
                translations={translations}
                onChangeFinished={loadTranslations}
                defaultLabel="Tracks"
                renderer={(label) => (
                  <Mui.Typography variant="body1" color={"white"}>
                    {label}
                  </Mui.Typography>
                )}
              />

              <LabelEditor
                id="info-2-value"
                section={section}
                translations={translations}
                onChangeFinished={loadTranslations}
                defaultLabel="7"
                renderer={(label) => (
                  <Mui.Typography variant="h5" color={"white"}>
                    <CountUp
                      end={Number(label)}
                      duration={20}
                      startVal={0}
                    />
                  </Mui.Typography>
                )}
              />
            </Mui.Box>

            <Mui.Box>
              <LabelEditor
                id="info-3-label"
                section={section}
                translations={translations}
                onChangeFinished={loadTranslations}
                defaultLabel="Tourist / year"
                renderer={(label) => (
                  <Mui.Typography variant="body1" color={"white"}>
                    {label}
                  </Mui.Typography>
                )}
              />

              <LabelEditor
                id="info-3-value"
                section={section}
                translations={translations}
                onChangeFinished={loadTranslations}
                defaultLabel="2000"
                renderer={(label) => (
                  <Mui.Typography variant="h5" color={"white"}>
                    <CountUp end={Number(label)} duration={20} startVal={0} />
                  </Mui.Typography>
                )}
              />
            </Mui.Box>

            <Slider {...HomeSliderSettings}>
              <Mui.Box px={1} height={"100px"}>
                <PhotoProvider>
                  <PhotoView src={image1}>
                    <img src={image1} height={"100%"} />
                  </PhotoView>
                </PhotoProvider>
              </Mui.Box>

              <Mui.Box px={1} height={"100px"}>
                <PhotoProvider>
                  <PhotoView src={image2}>
                    <img src={image2} height={"100%"} />
                  </PhotoView>
                </PhotoProvider>
              </Mui.Box>

              <Mui.Box px={1} height={"100px"}>
                <PhotoProvider>
                  <PhotoView src={image3}>
                    <img src={image3} height={"100%"} />
                  </PhotoView>
                </PhotoProvider>
              </Mui.Box>

              <Mui.Box px={1} height={"100px"}>
                <PhotoProvider>
                  <PhotoView src={image4}>
                    <img src={image4} height={"100%"} />
                  </PhotoView>
                </PhotoProvider>
              </Mui.Box>
            </Slider>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Box>
  );
};

export default HomeBanner;
