import { AxiosResponse } from "axios";
import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { ITranslation, ITranslationObj } from "interfaces/ITranslation";

const translationTransformer = (
  response: AxiosResponse<ITranslation[], any>
) => {
  const ret: ITranslationObj = {};

  response.data.forEach((element) => {
    ret[element.id] = element;
  });

  return ret;
};

export const translationsService = {
  getAll: async () => {
    return AxiosInstance.get<ITranslation[]>("translations").then(
      translationTransformer
    );
  },
  getBySection: async (section: string) => {
    return AxiosInstance.get<ITranslation[]>("translations/section", {
      params: {
        sectionId: section,
      },
    }).then(translationTransformer);
  },
  upsert: async (data: ITranslation) => {
    return AxiosInstance.post("translations/upsert", data).then(
      (response) => response.data
    );
  },
};
