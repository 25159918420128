import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { IEvents } from "interfaces/IEvents";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { EventsService } from "service/EventsService";
import { useImageUpload } from "components/imageUpload/useImageUpload";
import ImageUpload from "components/imageUpload/ImageUpload";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "service/tanstack";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  event?: IEvents;
};

const EventsPopup = (props: Props) => {
  const queryClient = useQueryClient();

  const { image, setImage } = useImageUpload();

  const form = useForm<IEvents>();

  const handleClose = () => {
    props.onClose();

    queryClient.invalidateQueries({
      queryKey: [queryKeys.events.getall],
    });
  };

  const upload = useMutation({
    mutationFn: EventsService.uploadImageById,
    onSuccess: handleClose,
  });

  const create = useMutation({
    mutationFn: EventsService.create,
    onSuccess: (data) => {
      if (image) {
        upload.mutate({ id: data.id, image });
      } else {
        handleClose();
      }
    },
  });

  const update = useMutation({
    mutationFn: EventsService.update,
    onSuccess: (data) => {
      if (image) {
        upload.mutate({ id: data.id, image });
      } else {
        handleClose();
      }
    },
  });

  const onSubmit = (data: IEvents) => {
    data.date = new Date(data.date?.toString() || "");
    if (!props.event) {
      create.mutate(data);
    } else {
      update.mutate(data);
    }
  };

  useEffect(() => {
    if (props.open && props.event) {
      form.reset({
        ...props.event,
        date: props.event.date
          ? dayjs(props.event.date).format("YYYY-MM-DD")
          : undefined,
      });
      setImage(undefined);
    } else {
      form.reset({});
      setImage(undefined);
    }
  }, [props.open]);

  return (
    <Mui.Dialog
      onClose={props.onClose}
      open={props.open}
      maxWidth="lg"
      fullWidth
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Mui.DialogTitle>
          <Mui.Stack direction={"row"} justifyContent={"space-between"}>
            <Mui.Typography variant="h3">
              {props.event
                ? `Edit Event: ${props.event.title}`
                : "Create Event"}
            </Mui.Typography>

            <Mui.IconButton onClick={() => props.onClose()}>
              <MuiIcons.Close />
            </Mui.IconButton>
          </Mui.Stack>
        </Mui.DialogTitle>

        <Mui.DialogContent>
          <Mui.Stack spacing={2}>
            <Mui.TextField
              label="Title"
              variant="standard"
              {...form.register("title")}
              fullWidth
              required
            />

            <Mui.TextField
              multiline
              rows={5}
              label="Description"
              fullWidth
              variant="standard"
              {...form.register("description")}
            />

            <Mui.FormControl>
              <Mui.FormLabel>Date</Mui.FormLabel>
              <Mui.TextField
                variant="standard"
                type="date"
                fullWidth
                required
                {...form.register("date")}
              />
            </Mui.FormControl>

            <Mui.TextField
              label="tags"
              variant="standard"
              {...form.register("tags")}
              fullWidth
            />

            <ImageUpload image={image} onUpload={setImage} />
          </Mui.Stack>
        </Mui.DialogContent>

        <Mui.DialogActions>
          <Mui.Button type="submit">Save</Mui.Button>
        </Mui.DialogActions>
      </form>
    </Mui.Dialog>
  );
};

export default EventsPopup;
