import * as MuiIcons from "@mui/icons-material";
import * as Mui from '@mui/material';

interface Props {
    paperColor?: string;
    buttonOnClick?: Function;
};

export const Plus = (props: Props) => {
    return(
        <Mui.Paper sx={{ height:"100%", display:"flex", justifyContent:"center", alignItems:"center", boxShadow:"none", backgroundColor: props.paperColor || "transparent"}}>
            <Mui.IconButton sx={{height:"fit-content"}} onClick={() => props.buttonOnClick && props.buttonOnClick()}>
                <MuiIcons.AddCircle sx={{fontSize:"50px"}} />
            </Mui.IconButton>
        </Mui.Paper>
    )
};