import * as Mui from "@mui/material";
import "react-photo-view/dist/react-photo-view.css";
import HomeAboutUs from "./HomeAboutUs";
import HomeBanner from "./HomeBanner";
import HomeEvents from "./HomeEvents";
import HomeService from "./HomeService";

const Home = () => {
  return (
    <>
      <Mui.Stack>
        <HomeBanner />

        <HomeEvents />

        <HomeAboutUs />

        <HomeService />
      </Mui.Stack>
    </>
  );
};

export default Home;
