import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";

import { IAssociation } from "interfaces/IAssociation";
import { useNavigate } from "react-router-dom";
import { sqids } from "utils/Sqids";
import MoreActionsMenu from "components/MoreActionsMenu";

import testimg from "assets/fshaltm/Artur Guni.jpeg";
import ImageSkeleton from "components/ImageSkeleton";
import { useAuthentication } from "hooks/useAuthentication";

type Props = {
  association: IAssociation;
  update?: () => void;
  delete?: () => void;
};

const AssociationItem = (props: Props) => {
  const navigate = useNavigate();

  const isAuthenticated = useAuthentication();

  return (
    <Mui.Grid item xs={12} sm={6} md={3}>
      <ImageSkeleton
        src={props.association.image}
        variant="rectangular"
        boxProps={{
          height: "200px",
          width: "100%",
        }}
      />

      <Mui.Stack spacing={2} mt={2} height={"calc(100% - 200px)"}>
        <Mui.Typography variant="h5">{props.association.name}</Mui.Typography>

        <Mui.Typography variant="body1" color="primary.200">
          {props.association.description}
        </Mui.Typography>

        <Mui.Stack
          direction={"row"}
          justifyContent={"space-between"}
          mt={"auto !important"}
        >
          <Mui.Button
            variant="text"
            sx={{ textTransform: "none", width: "fit-content" }}
            onClick={() =>
              navigate(`/anetaret/${sqids.encode([props.association.id])}`)
            }
          >
            About me <MuiIcons.KeyboardArrowRight />
          </Mui.Button>

          {isAuthenticated && (
            <MoreActionsMenu
              onEdit={() => props.update && props.update()}
              onDelete={() => props.delete && props.delete()}
            />
          )}
        </Mui.Stack>
      </Mui.Stack>
    </Mui.Grid>
  );
};

export default AssociationItem;
