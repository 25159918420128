import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { IEvents } from "interfaces/IEvents";

export const EventsService = {
  getAll: () => {
    return AxiosInstance.get<IEvents[]>("events").then((response) => {
      return response.data;
    });
  },

  getLatest: () => {
    return AxiosInstance.get<IEvents[]>("events/latest", {
      params: {
        eventsToTake: 3,
      },
    }).then((response) => {
      return response.data;
    });
  },

  create: (event: IEvents) => {
    return AxiosInstance.post<IEvents>("events/create", event).then(
      (response) => {
        return response.data;
      }
    );
  },

  update: (event: IEvents) => {
    return AxiosInstance.post<IEvents>("events/update", event).then(
      (response) => {
        return response.data;
      }
    );
  },

  delete: (id: number) => {
    return AxiosInstance.delete<IEvents>("events/delete", {
      params: { id },
    }).then((response) => {
      return response.data;
    });
  },

  uploadImageById: ({ id, image }: { id: number; image: File }) => {
    const formData = new FormData();
    formData.append("image", image);
    return AxiosInstance.postForm("events/image", formData, {
      params: {
        id,
      },
    }).then((response) => {
      return response.data;
    });
  },
};
