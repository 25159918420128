import { PropsWithChildren, useMemo, ReactNode, useId } from "react";
import { Link } from "react-router-dom";
import * as Mui from "@mui/material";

interface PropsMenuItem {
  name: string;
  to: string;
  submenu?: boolean;
  imgSrc?: string;
  children?: PropsMenuItem[];
}

const MenuItem = (props: PropsMenuItem) => {
  const menuItemId = useId();

  const classname = useMemo(() => {
    if (props.children && !props.submenu) {
      return "dropdown";
    } else if (props.submenu) {
      return "dropdown-submenu";
    }
    return "";
  }, []);

  return (
    <li className={classname}>
      <Link to={props.to}>
        
        {props.imgSrc && (
          <img src={props.imgSrc} title="img" />
        )}

        {props.name}
      </Link>

      {props.children && (
        <ul>
          {props.children.map((element, index) => (
            <MenuItem
              {...element}
              key={`${menuItemId}-menu-item-${index}`}
              submenu={(element.children && element.children.length > 0) || false}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

interface PropsMenuList extends PropsWithChildren {
  position: "right" | "left";
};

const MenuList = (props: PropsMenuList) => {

  if (props.position === "left") {
    return (
      <ul id="main-menu">
        {props.children}
      </ul>
    );
  }

  if (props.position === "right") {
    return (
      <div className="menu-right">
        <ul className="lan-menu">
          {props.children}
        </ul>
      </div>
    )
  }

  return (
    <div>
      {props.children}
    </div>
  )
}

interface PropsMenu extends PropsWithChildren { 
  isOpen: boolean;
}

export const Menu = (props: PropsMenu) => {

  return (
    <Mui.Box
      className="menu-cnt"
      sx={{
        height: {
          xs: props.isOpen ? 'auto' : 0,
          lg: 'auto',
        },
      }}
    >
      {props.children}
    </Mui.Box>
  )
}
Menu.List = MenuList;
Menu.Item = MenuItem;