import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { ImageType } from "components/imageUpload/useImageUpload";
import { IMembers } from "interfaces/IMembers";

export const MembersService = {
  getAll: () => {
    return AxiosInstance.get("members").then((response) => {
      return response.data;
    });
  },
  getById: (id: number) => {
    return AxiosInstance.get<IMembers>(`members/${id}`).then((response) => {
      return response.data;
    });
  },
  getGuidat: () => {
    return AxiosInstance.get<IMembers[]>("members/guides").then((response) => {
      return response.data;
    });
  },
  getShpetimiMalor: () => {
    return AxiosInstance.get<IMembers[]>("members/shpetimi-malor").then(
      (response) => {
        return response.data;
      }
    );
  },
  getNgjitjaSportive: () => {
    return AxiosInstance.get<IMembers[]>("members/ngjitja-sportive").then(
      (response) => {
        return response.data;
      }
    );
  },
  create: (data: IMembers) => {
    return AxiosInstance.post<IMembers>("members/create", data).then(
      (response) => {
        return response.data;
      }
    );
  },
  update: (data: IMembers) => {
    return AxiosInstance.post("members/update", data).then((response) => {
      return response.data;
    });
  },
  delete: (id: number) => {
    return AxiosInstance.delete("members/delete", {
      params: {
        id,
      },
    }).then((response) => {
      return response.data;
    });
  },

  uploadImageById: ({ id, image }: { id: number; image: File }) => {
    const formData = new FormData();
    formData.append("image", image);
    return AxiosInstance.postForm("members/image", formData, {
      params: {
        id,
      },
    }).then((response) => {
      return response.data;
    });
  },
};
