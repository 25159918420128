import { CSSProperties, PropsWithChildren, useId, useMemo } from "react";

import { Link, LinkProps } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";

import { Container } from "./Container";
import { PropsParallax } from "./Common";

import "../../css/bootstrap-grid.css";
import "../../css/style.css";
import "../../css/glide.css";
import "../../css/magnific-popup.css";
import "../../css/content-box.css";
import "../../css/contact-form.css";
import "../../css/skin.css";
import "../../css/media-box.css";
import "../../css/prettify.css";
import "../../css/social.css";

import "../../assets/media/icons/iconsmind/line-icons.min.css";
import "../../assets/media/icons/fontawesome-6.5/css/all.css";

interface PropsHeader {
  type?: "base" | "video" | "slider" | "image";
  title: string;
  subTitle?: string;
  breadcrumbs?: {
    to?: LinkProps["to"];
    title: string;
  }[];
  bgImg?: CSSProperties["backgroundImage"];
  sliderImgs?: CSSProperties["backgroundImage"][];
  video?: {
    poster?: HTMLVideoElement["poster"];
    source: HTMLSourceElement["src"];
    type: HTMLSourceElement["type"];
  };
  parallax?: PropsParallax;
}

const Header = (props: PropsHeader) => {
  const headerId = useId();
  
  const headerClassname = useMemo(() => {
    if (props.type === "image") {
      return "header-image";
    } else if (props.type === "slider") {
      return "header-slider";
    } else if (props.type === "video") {
      return "header-video";
    }
    
    return "header-base";
  }, [props.type]);

  return (
    <header
      className={`${headerClassname}`}
      style={{
        backgroundImage: props.parallax ? "" : `url(${props.bgImg || ""})`,
      }}
    >
      {props.parallax && props.bgImg && (
        <Parallax
          speed={500}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%"
          }}
          translateY={[-60, 50]}
          translateX={[0, 0]}
          {...props.parallax}
          className={`${props.parallax.className} ${props.parallax.animation}`}
        >
          <img
            src={props.bgImg}
            height={"100%"}
            width={"100%"}
          />
        </Parallax>
      )}

      {props.type === "slider" && props.sliderImgs && props.sliderImgs.length > 0 && (
        <div className="background-slider">
          {props.sliderImgs.map((element, index) => {
            return (
              <div
                key={`${headerId}-slider-image-${index}`}
                className={index === 0 ? "active" : ""}
                style={{
                  backgroundImage: `url(${element || ""})`,
                }}
              />
            )
          })}
        </div>
      )}

      {props.type === "video" && props.video && (
        <video autoPlay loop muted poster={props.video.poster}>
            <source
              src={props.video.source}
              type={props.video.type}
            />
        </video>
      )}

      <Container>
        <h1>{props.title}</h1>
        {props.subTitle && (
          <h2>{props.subTitle}</h2>
        )}
        <ol className="breadcrumb">
          {props.breadcrumbs?.map((crumb, index) => {
            return (
              <li key={`${headerId}-breadcrumb-${index}`}>
                <Link to={crumb.to || "#"}>{crumb.title}</Link>
              </li>
            );
          })}
        </ol>
      </Container>
    </header>
  );
}

const Main = (props: PropsWithChildren) => {
  return (
    <main>
      {props.children}
    </main>
  )
}

export const Page = (props: PropsWithChildren) => {
  return (
    <>
      {props.children}
    </>
  );
}
Page.Header = Header;
Page.Main = Main;