import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { PageHeader } from "components/PageHeader";
import image_1 from "assets/fshaltm/pexels-pixabay-326058.jpg";
import { ParallaxContainer } from "components/ParallaxContainer";
import { useEffect, useState } from "react";
import { IEvents } from "interfaces/IEvents";
import ImageSkeleton from "components/ImageSkeleton";
import { Plus } from "components/Plus";
import EventsPopup from "./EventsPopup";
import MoreActionsMenu from "components/MoreActionsMenu";
import { EventsService } from "service/EventsService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "service/tanstack";
import { useAuthentication } from "hooks/useAuthentication";

type Props = {};

export const Events = (props: Props) => {
  const queryClient = useQueryClient();

  const isAuthenticated = useAuthentication();

  const [eventPopupOpen, setEventPopupOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvents | undefined>();

  const events = useQuery({
    queryKey: [queryKeys.events.getall],
    queryFn: EventsService.getAll,
  });

  const remove = useMutation({
    mutationFn: EventsService.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.events.getall],
      });
    },
  });

  const onOpenPopup = (event: IEvents) => {
    setSelectedEvent(event);
    setEventPopupOpen(true);
  };

  const onClosePopup = () => {
    setEventPopupOpen(false);
    setSelectedEvent(undefined);
  };

  const onDelete = (id: number) => {
    remove.mutate(id);
  };

  return (
    <>
      <PageHeader
        title="Eventet"
        subtitle="Eksploro nje bote te pa eksploruar"
        parallax={<ParallaxContainer image={image_1} animated />}
        breadcrumbs={[
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Eventet",
          },
        ]}
      />

      <Mui.Container sx={{ my: 2 }}>
        <Mui.Stack spacing={4}>
          {events.data?.map((event) => (
            <Mui.Paper sx={{ p: 2 }}>
              <Mui.Stack spacing={2}>
                <ImageSkeleton
                  src={event.image}
                  skeletonProps={{
                    sx: {
                      minHeight: "200px",
                    },
                  }}
                />

                <Mui.Typography variant="body1" whiteSpace={"pre-line"}>
                  {event.description}
                </Mui.Typography>

                <Mui.Box display="flex" color="primary.main" flexWrap={"wrap"}>
                  {event.tags &&
                    event.tags.split(",").map((tag) => (
                      <>
                        <MuiIcons.Tag />
                        <Mui.Typography>{tag}</Mui.Typography>
                      </>
                    ))}
                </Mui.Box>

                {isAuthenticated && (
                  <Mui.Stack direction={"row"} justifyContent={"end"}>
                    <MoreActionsMenu
                      onEdit={() => onOpenPopup(event)}
                      onDelete={() => onDelete(event.id)}
                    />
                  </Mui.Stack>
                )}
              </Mui.Stack>
            </Mui.Paper>
          ))}

          {isAuthenticated && (
            <Plus buttonOnClick={() => setEventPopupOpen(true)} />
          )}
        </Mui.Stack>
      </Mui.Container>

      <EventsPopup
        onClose={onClosePopup}
        open={eventPopupOpen}
        event={selectedEvent}
      />
    </>
  );
};
