import * as Mui from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import image2 from "assets/fshaltm/mountain-landscape-2031539_1280.jpg";
import MemberCard from "components/members/MemberCard";
import MemberContainer from "components/members/MemberContainer";
import { MemberFormPopup } from "components/members/MemberFormPopup";
import { PageHeader } from "components/PageHeader";
import { ParallaxContainer } from "components/ParallaxContainer";
import { Plus } from "components/Plus";
import { useAuthentication } from "hooks/useAuthentication";
import { IMembers } from "interfaces/IMembers";
import { Positives } from "pages/components/Positives";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MembersService } from "service/MembersService";
import { queryKeys } from "service/tanstack";
import { sqids } from "utils/Sqids";

const Guidat = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const isAuthenticated = useAuthentication();

  const [openPopup, setopenPopup] = useState<boolean>(false);
  const [guidaEdit, setguidaEdit] = useState<IMembers>();

  const members = useQuery({
    queryKey: [queryKeys.members.getGuidat],
    queryFn: MembersService.getGuidat,
  });

  const remove = useMutation({
    mutationFn: MembersService.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.members.getGuidat],
      });
    },
  });

  const updateGuidat = (member: IMembers) => {
    setguidaEdit(member);
    setopenPopup(true);
  };

  const deleteGuidat = (id: number) => {
    remove.mutate(id);
  };

  const onClosePopUp = () => {
    setguidaEdit(undefined);
    setopenPopup(false);
  };

  return (
    <>
      <PageHeader
        title="Guidat"
        subtitle="Guidat ne shqiperi"
        parallax={<ParallaxContainer image={image2} animated />}
        breadcrumbs={[
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Guidat",
          },
        ]}
      />

      <Mui.Stack bgcolor="background.paper">
        <MemberContainer>
          {members.data?.map((member, index) => {
            return (
              <MemberCard
                key={`member-${index}-${member.name}`}
                member={member}
                onClickItem={() =>
                  navigate(`/member/${sqids.encode([member.id])}`, {
                    state: {
                      origin: "guidat",
                    },
                  })
                }
                onEditItem={() => updateGuidat(member)}
                onDeleteItem={() => deleteGuidat(member.id)}
              />
            );
          })}

          {isAuthenticated && <Plus buttonOnClick={() => setopenPopup(true)} />}
        </MemberContainer>

        <Positives />
      </Mui.Stack>

      <MemberFormPopup
        open={openPopup}
        onClose={onClosePopUp}
        member={guidaEdit}
        memberType={"GUIDE"}
      />
    </>
  );
};

export default Guidat;
