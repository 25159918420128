import * as MuiIcons from "@mui/icons-material";
import * as Mui from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ImageUpload from "components/imageUpload/ImageUpload";
import { useImageUpload } from "components/imageUpload/useImageUpload";
import dayjs from "dayjs";
import { IAssociation } from "interfaces/IAssociation";
import { IMembers } from "interfaces/IMembers";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AssociationService } from "service/AssociationService";
import { MembersService } from "service/MembersService";
import { queryKeys } from "service/tanstack";
import { alpinTheme } from "theme/AlpinTheme";
import { MemberTypes } from "utils/MemberTypes";

type Props = {
  open: boolean;
  member?: IMembers;
  memberType?: keyof typeof MemberTypes;
  association?: IAssociation;
  onClose?: (value: boolean) => void;
  onSubmit?: () => void;
};

export const MemberFormPopup = (props: Props) => {
  const queryClient = useQueryClient();

  const { register, handleSubmit, reset, control } = useForm<IMembers>();

  const memberTypeValues = Object.values(MemberTypes);

  const { image, setImage } = useImageUpload();

  const handleClose = (isSubmit: boolean = false) => {
    props.onClose && props.onClose(!props.open);
    setImage(null);
    reset({});
    queryClient.invalidateQueries({
      queryKey: [queryKeys.assocaitions.getbyid],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKeys.members.getall],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKeys.members.getGuidat],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKeys.members.getNgjitjeSportive],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKeys.members.getShpetimiMalor],
    });
  };

  const associations = useQuery<IAssociation[]>({
    queryKey: [queryKeys.assocaitions.getall],
    queryFn: AssociationService.getAll,
  });

  const upload = useMutation({
    mutationFn: MembersService.uploadImageById,
    onSuccess: handleClose,
  });

  const onSuccessUpdate = (data: IMembers) => {
    if (image) {
      upload.mutate({ id: data.id, image });
    } else {
      handleClose();
    }
  };

  const create = useMutation({
    mutationFn: MembersService.create,
    onSuccess: onSuccessUpdate,
  });

  const update = useMutation({
    mutationFn: MembersService.update,
    onSuccess: onSuccessUpdate,
  });

  useEffect(() => {
    if (props.open) {
      const dateofbirth = props.member?.dateofbirth
        ? dayjs(props.member?.dateofbirth).format("YYYY-MM-DD")
        : undefined;

      reset({
        ...props.member,
        dateofbirth: dateofbirth,
        type: (props.memberType && MemberTypes[props?.memberType]) || "",
        associations: props.association?.id || undefined,
      });
    }
  }, [props.open]);

  const onSubmit = (data: IMembers) => {
    if (props.memberType) {
      data.type = MemberTypes[props?.memberType];
    }

    if (!props.member) {
      create.mutate(data);
    } else {
      update.mutate(data);
    }
  };

  return (
    <Mui.Dialog onClose={() => handleClose()} open={props.open}>
      <Mui.Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={1}
      >
        <Mui.DialogTitle>
          {`Manage Guida: ${props.member?.name ? props.member.name : ""}`}
        </Mui.DialogTitle>

        <Mui.IconButton onClick={() => handleClose()}>
          <MuiIcons.Close />
        </Mui.IconButton>
      </Mui.Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.DialogContent>
          <Mui.Grid container spacing={3} columns={1}>
            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guidatname"
                label="Name"
                type="text"
                autoComplete="current-name"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("name")}
                required
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                label="Date of birth"
                type="date"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                  shrink: true,
                }}
                {...register("dateofbirth")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <Mui.TextField
                    fullWidth
                    id="standard-guidatdescription"
                    label="Description"
                    type="text"
                    autoComplete="current-guidatdescription"
                    variant="standard"
                    multiline
                    rows={3}
                    InputLabelProps={{
                      style: { color: alpinTheme.palette.text.primary },
                    }}
                    {...field}
                  />
                )}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guidatposition"
                label="Position"
                type="text"
                autoComplete="current-guidatposition"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("position")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guidatzone"
                label="Zone"
                type="text"
                autoComplete="current-guidatzone"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("zone")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guidatplace"
                label="Place"
                type="text"
                autoComplete="current-guidatplace"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("place")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guidatcontact"
                label="Contact"
                type="tel"
                autoComplete="current-guidatcontact"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("contact")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guidatemail"
                label="Email"
                type="email"
                autoComplete="current-guidatemail"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("email")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-categorization"
                label="Categorization"
                type="text"
                autoComplete="current-categorization"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("categorization")}
              />
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.TextField
                fullWidth
                id="standard-guide"
                label="Guide"
                type="text"
                autoComplete="current-guide"
                variant="standard"
                InputLabelProps={{
                  style: { color: alpinTheme.palette.text.primary },
                }}
                {...register("guide")}
              />
            </Mui.Grid>

            {!props.memberType && (
              <Mui.Grid item xs={12}>
                <Mui.InputLabel
                  id="standard-member-type-label"
                  sx={{ color: "black" }}
                >
                  Member Type
                </Mui.InputLabel>
                <Mui.Select
                  fullWidth
                  id="standard-member-type"
                  labelId="standard-member-type-label"
                  autoComplete="current-member-type"
                  variant="standard"
                  {...register("type")}
                >
                  {memberTypeValues.map((type, index) => {
                    return (
                      <Mui.MenuItem key={`type-${type}-${index}`} value={type}>
                        {type.split("-").join(" ")}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.Grid>
            )}

            {!props.association && (
              <Mui.Grid item xs={12}>
                <Mui.InputLabel
                  id="standard-member-association-label"
                  sx={{ color: "black" }}
                >
                  Association
                </Mui.InputLabel>
                <Mui.Select
                  fullWidth
                  id="standard-member-association"
                  labelId="standard-member-association-label"
                  autoComplete="current-member-association"
                  variant="standard"
                  {...register("associations")}
                >
                  {associations.data?.map((asso, index) => {
                    return (
                      <Mui.MenuItem
                        key={`association-${asso.name}-${index}`}
                        value={asso.id}
                      >
                        {asso.name}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.Grid>
            )}

            <Mui.Grid item xs={12}>
              <ImageUpload image={image} onUpload={setImage} />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.DialogContent>

        <Mui.DialogActions>
          <Mui.Button type="submit">Save</Mui.Button>
        </Mui.DialogActions>
      </form>
    </Mui.Dialog>
  );
};
