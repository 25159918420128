import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { IAboutus } from "interfaces/IAboutus";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { watch } from "fs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AboutusService } from "service/AboutusService";
import { queryKeys } from "service/tanstack";

interface Props {
  open: boolean;
  onClose: () => void;
  data?: IAboutus;
}

const AboutUsForm = (props: Props) => {
  const queryClient = useQueryClient();

  const form = useForm<IAboutus>();

  useEffect(() => {
    if (props.open) {
      form.reset(props.data);
    }
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
    form.reset({});

    queryClient.invalidateQueries({
      queryKey: [queryKeys.aboutus.getall],
    });
  };

  const create = useMutation({
    mutationFn: AboutusService.create,
    onSuccess: handleClose,
  });

  const update = useMutation({
    mutationFn: AboutusService.update,
    onSuccess: handleClose,
  });

  const onSubmit = (data: IAboutus) => {
    if (props.data) {
      update.mutate(data);
    } else {
      create.mutate(data);
    }
  };

  return (
    <Mui.Dialog onClose={handleClose} open={props.open} maxWidth="lg" fullWidth>
      <Mui.DialogTitle>
        <Mui.Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Mui.Typography variant="h5">
            {props.data
              ? `Edit aboutus: ${props.data.title}`
              : "Create aboutus"}
          </Mui.Typography>

          <Mui.IconButton onClick={props.onClose}>
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Stack>
      </Mui.DialogTitle>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Mui.DialogContent>
          <Mui.Stack spacing={2}>
            <Mui.TextField
              label="title"
              variant="standard"
              {...form.register("title")}
            />

            <Controller
              control={form.control}
              name="description"
              render={({ field }) => (
                <Mui.TextField
                  label="description"
                  variant="standard"
                  multiline
                  rows={5}
                  fullWidth
                  {...field}
                />
              )}
            />

            <Mui.TextField
              label="order"
              variant="standard"
              type="number"
              {...form.register("order")}
            />
          </Mui.Stack>
        </Mui.DialogContent>

        <Mui.DialogActions>
          <Mui.Button type="submit">Save</Mui.Button>
        </Mui.DialogActions>
      </form>
    </Mui.Dialog>
  );
};

export default AboutUsForm;
