import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { IAuthentication } from "interfaces/IAuthentication";

export const AuthenticationService = {
  login: (data: IAuthentication) => {
    return AxiosInstance.post("auth/login", data).then((response) => {
      localStorage.setItem("token", response.data["accessToken"]);

      return response.data;
    });
  },
  check: (token: string) => {
    return AxiosInstance.post("auth/check", {
      token,
    }).then((response) => {
      return response.data;
    });
  },
  logout: () => {
    return Promise.resolve(localStorage.setItem("token", ""));
  },
};
