import * as Mui from "@mui/material";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  sx?: Mui.SxProps;
}

const MemberContainer = (props: Props) => {
  return (
    <Mui.Stack
      spacing={4}
      sx={{
        backgroundColor: "#eef3f7",
        px: {
          xs: 2,
          md: 4,
          lg: 15,
        },
        py: {
          xs: 2,
          md: 5,
        },
        ...props.sx,
      }}
    >
      {props.children}
    </Mui.Stack>
  );
};

export default MemberContainer;
