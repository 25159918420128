import { AxiosInstance } from "axiosInstance/AxiosInstance";
import { IAboutus } from "interfaces/IAboutus";

export const AboutusService = {
  getAll: () => {
    return AxiosInstance.get<IAboutus[]>("aboutus").then((response) => {
      return response.data;
    });
  },
  create: (data: IAboutus) => {
    return AxiosInstance.post("aboutus/create", data).then((response) => {
      return response.data;
    });
  },
  update: (data: IAboutus) => {
    return AxiosInstance.patch("aboutus/update", data).then((response) => {
      return response.data;
    });
  },
  remove: (id: number) => {
    return AxiosInstance.delete("aboutus/delete", {
      params: { id },
    }).then((response) => {
      return response.data;
    });
  },
};
